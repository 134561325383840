export const telosPools = [
  {
    id: 'omnidex-wtlos-charm',
    name: 'WTLOS-CHARM LP',
    token: 'WTLOS-CHARM LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0x933F83735f26e51c61955b4fCA88F13fbd423A0C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhWtlosCharm',
    earnedTokenAddress: '0x49AC06648789d235BFc7D2108cD09594A51f584A',
    earnContractAddress: '0x49AC06648789d235BFc7D2108cD09594A51f584A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-wtlos-charm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['WTLOS', 'CHARM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E/0xd2504a02fABd7E546e41aD39597c377cA8B0E1Df',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-usdc-wtlos',
    name: 'USDC-WTLOS LP',
    token: 'USDC-WTLOS LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0x651Fcc98a348C91FDF087903c25A638a25344dFf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhUsdcWtlos',
    earnedTokenAddress: '0xDEAa7fC91e3d81C2C382Db559C29F3eb742B7B74',
    earnContractAddress: '0xDEAa7fC91e3d81C2C382Db559C29F3eb742B7B74',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-usdc-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['WTLOS', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-wtlos-weth',
    name: 'WTLOS-WETH LP',
    token: 'WTLOS-WETH LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0xd0D08c23d0cFd88457cDD43CaF34c16E3A29e85F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhWtlosWeth',
    earnedTokenAddress: '0xC76eF284E616567d92802261d4193328f598C2d1',
    earnContractAddress: '0xC76eF284E616567d92802261d4193328f598C2d1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-wtlos-weth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['WTLOS', 'WETH'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E/0xfa9343c3897324496a05fc75abed6bac29f8a40f',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-wtlos-wbtc',
    name: 'WTLOS-WBTC LP',
    token: 'WTLOS-WBTC LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0x427E9A7bb848444a72faA3248c48F3B302429725',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhWtlosWbtc',
    earnedTokenAddress: '0xd5397b85f4677E973b992680deA429fE102CA43D',
    earnContractAddress: '0xd5397b85f4677E973b992680deA429fE102CA43D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-wtlos-wbtc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['WTLOS', 'WBTC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E/0xf390830df829cf22c53c8840554b98eafc5dcbc2',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-usdc-usdt',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0x8805F519663E47aBd6adbA4303639f69e51fd112',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhUSDCUSDT',
    earnedTokenAddress: '0xE95EB1DFcFe6E92763598A0270cE7BdadD7542C4',
    earnContractAddress: '0xE95EB1DFcFe6E92763598A0270cE7BdadD7542C4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['USDC', 'USDT'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b/0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-bnb-wtlos',
    name: 'BNB-WTLOS LP',
    token: 'BNB-WTLOS LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0x72801E883EBF8548D0bF9BcF149DF72D84542448',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhBnbWtlos',
    earnedTokenAddress: '0x27519464F504ec385F7318849a6AB544111167b9',
    earnContractAddress: '0x27519464F504ec385F7318849a6AB544111167b9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-bnb-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['BNB', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x2c78f1b70ccf63cdee49f9233e9faa99d43aa07e/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-ftm-wtlos',
    name: 'FTM-WTLOS LP',
    token: 'FTM-WTLOS LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0x5b9447EF36abf518cca729bF08E8D72b24a69BDF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhFtmWtlos',
    earnedTokenAddress: '0x73a9a6F798f8664bA0F5AC2F5dD89e303fEcAa9b',
    earnContractAddress: '0x73a9a6F798f8664bA0F5AC2F5dD89e303fEcAa9b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-ftm-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['FTM', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0xc1be9a4d5d45beeacae296a7bd5fadbfc14602c4/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-matic-wtlos',
    name: 'MATIC-WTLOS LP',
    token: 'MATIC-WTLOS LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0x7B90a6355FAc6F0b928aa815335b5Aa42dD2749e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhMaticWtlos',
    earnedTokenAddress: '0x54C8e46012F9D1DEa678a945b7a70e254e48455B',
    earnContractAddress: '0x54C8e46012F9D1DEa678a945b7a70e254e48455B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-matic-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['MATIC', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x332730a4f6e03d9c55829435f10360e13cfa41ff/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-avax-wtlos',
    name: 'AVAX-WTLOS LP',
    token: 'AVAX-WTLOS LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0xa6Dc4d4d8fa2c6f8667B096C368e246718982787',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhAvaxWtlos',
    earnedTokenAddress: '0x54A91965a9231e870cC0a601169d8b29b581440c',
    earnContractAddress: '0x54A91965a9231e870cC0a601169d8b29b581440c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-avax-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['AVAX', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x7c598c96d02398d89fbcb9d41eab3df0c16f227d/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-wone-wtlos',
    name: 'WONE-WTLOS LP',
    token: 'WONE-WTLOS LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0xE36aaF76f4d769799C29ebB63f35023239C28B56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhWoneWtlos',
    earnedTokenAddress: '0x2EE0a0731399f0d4Af3FdDBEE303e86b84cB23f7',
    earnContractAddress: '0x2EE0a0731399f0d4Af3FdDBEE303e86b84cB23f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-wone-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['WONE', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x0caE51e1032e8461f4806e26332c030E34De3aDb/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-karma-charm',
    name: 'KARMA-CHARM LP',
    token: 'KARMA-CHARM LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0xC4dC3ce0f4D377DE26b575ED4E2501245d04945f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhKarmaCharm',
    earnedTokenAddress: '0x6d83a06194Ca237C9A157655492Cb3e6daa857c1',
    earnContractAddress: '0x6d83a06194Ca237C9A157655492Cb3e6daa857c1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-karma-charm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['KARMA', 'CHARM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x730d2Fa7dC7642E041bcE231E85b39e9bF4a6a64/0xd2504a02fABd7E546e41aD39597c377cA8B0E1Df',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-karma-elk',
    name: 'KARMA-ELK LP',
    token: 'KARMA-ELK LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0xa2AB1530F57Ed920027c9D1d66eE9582E6cd7913',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhKarmaElk',
    earnedTokenAddress: '0x5e1a727377c4F4C24b4a5BaC331D26cF3C90CBc2',
    earnContractAddress: '0x5e1a727377c4F4C24b4a5BaC331D26cF3C90CBc2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-karma-elk',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['KARMA', 'ELK'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x730d2Fa7dC7642E041bcE231E85b39e9bF4a6a64/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-karma-wtlos',
    name: 'KARMA-WTLOS LP',
    token: 'KARMA-WTLOS LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0xC72ca4D9Ef358E1d8989e36dF9dFA28378137D20',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhKarmaWtlos',
    earnedTokenAddress: '0x10DA15D3A4169E64b9EA51C5Ad174c03bd0625D6',
    earnContractAddress: '0x10DA15D3A4169E64b9EA51C5Ad174c03bd0625D6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-karma-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['KARMA', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x730d2Fa7dC7642E041bcE231E85b39e9bF4a6a64/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-karma-douge',
    name: 'KARMA-DOUGE LP',
    token: 'KARMA-DOUGE LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0xf8EB0771d72db674f4FDEe54434080d0bdd0cB41',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhKarmaDouge',
    earnedTokenAddress: '0x10E9fbC3b3aFFf0C330dc19778474cA7Fe17B6e3',
    earnContractAddress: '0x10E9fbC3b3aFFf0C330dc19778474cA7Fe17B6e3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-karma-douge',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['KARMA', 'DOUGE'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x730d2Fa7dC7642E041bcE231E85b39e9bF4a6a64/0xc6BC7A8dfA0f57Fe7746Ac434c01cD39679b372c',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'omnidex-dmmy-karma',
    name: 'DMMY-KARMA LP',
    token: 'DMMY-KARMA LP',
    tokenDescription: 'OmniDex',
    tokenAddress: '0x76Bf9208b92C75c94A5723f4a7343C26BB5739B8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhDmmyKarma',
    earnedTokenAddress: '0x15cD93a074740Ee4c14a102C9e2A9D2D3F760D4F',
    earnContractAddress: '0x15cD93a074740Ee4c14a102C9e2A9D2D3F760D4F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'omnidex-dmmy-karma',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OmniDex',
    assets: ['DMMY', 'KARMA'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://omnidex.finance/add/0x2f15F85a6c346C0a2514Af70075259e503E7137B/0x730d2Fa7dC7642E041bcE231E85b39e9bF4a6a64',
    buyTokenUrl: 'https://omnidex.finance/swap',
    createdAt: 1649018934,
  },
  {
    id: 'zappy-zap-wtlos',
    name: 'ZAP-WTLOS LP',
    token: 'ZAP-WTLOS LP',
    tokenDescription: 'Zappy',
    tokenAddress: '0x774d427B2105849A0FBb6f49c432C087E3607F6F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhZapWtlos',
    earnedTokenAddress: '0x856Ab6A84fdD0C35cDc6B3710DEC83203B470637',
    earnContractAddress: '0x856Ab6A84fdD0C35cDc6B3710DEC83203B470637',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zappy-zap-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Zappy',
    assets: ['ZAP', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://www.zappy.finance/liquidity/pool?main=0x9a271e3748f59222f5581bae2540daa5806b3f77&base=tlos',
    buyTokenUrl:
      'https://www.zappy.finance/swap?from=tlos&to=0x9a271e3748f59222f5581bae2540daa5806b3f77',
    createdAt: 1649183494,
  },
  {
    id: 'zappy-usdc-wtlos',
    name: 'USDC-WTLOS LP',
    token: 'USDC-WTLOS LP',
    tokenDescription: 'Zappy',
    tokenAddress: '0xeA96c8d2FFA10FFeF65a87C957C27114051336F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhZapUsdcWtlos',
    earnedTokenAddress: '0x84027D689DaB82D0548FE1DA273fd68187a7C578',
    earnContractAddress: '0x84027D689DaB82D0548FE1DA273fd68187a7C578',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zappy-usdc-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Zappy',
    assets: ['USDC', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://www.zappy.finance/liquidity/pool?main=0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b&base=tlos',
    buyTokenUrl:
      'https://www.zappy.finance/swap?from=tlos&to=0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
    createdAt: 1649183494,
  },
  {
    id: 'zappy-wtlos-weth',
    name: 'WTLOS-WETH LP',
    token: 'WTLOS-WETH LP',
    tokenDescription: 'Zappy',
    tokenAddress: '0xA8A2ccbD5B130bd965Dc2C24fc8938AEa7493216',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhZapWtlosWeth',
    earnedTokenAddress: '0xc8A34969306a26907B12fD44A51DCb10EB19D47E',
    earnContractAddress: '0xc8A34969306a26907B12fD44A51DCb10EB19D47E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zappy-wtlos-weth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Zappy',
    assets: ['WETH', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://www.zappy.finance/liquidity/pool?main=0xfa9343c3897324496a05fc75abed6bac29f8a40f&base=tlos',
    buyTokenUrl:
      'https://www.zappy.finance/swap?from=tlos&to=0xfa9343c3897324496a05fc75abed6bac29f8a40f',
    createdAt: 1649183494,
  },
  {
    id: 'zappy-wtlos-wbtc',
    name: 'WTLOS-WBTC LP',
    token: 'WTLOS-WBTC LP',
    tokenDescription: 'Zappy',
    tokenAddress: '0x2C3dd9b87f5EcD49F6AE3566a5d61D8Ea6Dc21c2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhZapWtlosWbtc',
    earnedTokenAddress: '0x161DDe5cB80564434a686311FD43e01b1B1ee5E5',
    earnContractAddress: '0x161DDe5cB80564434a686311FD43e01b1B1ee5E5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zappy-wtlos-wbtc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Zappy',
    assets: ['WBTC', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://www.zappy.finance/swap?from=tlos&to=0xf390830DF829cf22c53c8840554B98eafC5dCBc2',
    addLiquidityUrl:
      'https://www.zappy.finance/liquidity/pool?main=0xf390830DF829cf22c53c8840554B98eafC5dCBc2&base=tlos',
    createdAt: 1649183494,
  },
  {
    id: 'zappy-matic-wtlos',
    name: 'MATIC-WTLOS LP',
    token: 'MATIC-WTLOS LP',
    tokenDescription: 'Zappy',
    tokenAddress: '0x06754b2a38782AA5c9B071Dc70A5C49457C7eBD1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhZapMaticWtlos',
    earnedTokenAddress: '0xa2Fc4Ba0D10438D8B63B369D00a3bAb29c319eEC',
    earnContractAddress: '0xa2Fc4Ba0D10438D8B63B369D00a3bAb29c319eEC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zappy-matic-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Zappy',
    assets: ['MATIC', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://www.zappy.finance/swap?from=tlos&to=0x332730a4F6E03D9C55829435f10360E13cfA41Ff',
    addLiquidityUrl:
      'https://www.zappy.finance/liquidity/pool?main=0x332730a4F6E03D9C55829435f10360E13cfA41Ff&base=tlos',
    createdAt: 1649183494,
  },
  {
    id: 'zappy-avax-wtlos',
    name: 'AVAX-WTLOS LP',
    token: 'AVAX-WTLOS LP',
    tokenDescription: 'Zappy',
    tokenAddress: '0x8DFb20737aF995F78fAa5eB0349a7766EE3a543E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhZapAvaxWtlos',
    earnedTokenAddress: '0x8C6b71B9967AfE4B633759f0714C593C2ac38e85',
    earnContractAddress: '0x8C6b71B9967AfE4B633759f0714C593C2ac38e85',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zappy-avax-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Zappy',
    assets: ['AVAX', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://www.zappy.finance/swap?from=tlos&to=0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D',
    addLiquidityUrl:
      'https://www.zappy.finance/liquidity/pool?main=0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D&base=tlos',
    createdAt: 1649183494,
  },
  {
    id: 'zappy-wtlos-usdt',
    name: 'WTLOS-USDT LP',
    token: 'WTLOS-USDT LP',
    tokenDescription: 'Zappy',
    tokenAddress: '0xa58615cB042e2ba96c8bef072aad256c15aC8372',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhZapWtlosUsdt',
    earnedTokenAddress: '0x9a4743E8476B2e7bF5f6d314215523c8ca8f24C5',
    earnContractAddress: '0x9a4743E8476B2e7bF5f6d314215523c8ca8f24C5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zappy-wtlos-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Zappy',
    assets: ['USDT', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://www.zappy.finance/swap?from=tlos&to=0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
    addLiquidityUrl:
      'https://www.zappy.finance/liquidity/pool?main=0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73&base=tlos',
    createdAt: 1649183494,
  },
  {
    id: 'zappy-ftm-wtlos',
    name: 'FTM-WTLOS LP',
    token: 'FTM-WTLOS LP',
    tokenDescription: 'Zappy',
    tokenAddress: '0x62514Ad55D9fbb7eA66a915a1A1E11872F5FAA90',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhZapFtmWtlos',
    earnedTokenAddress: '0x1226f2eBC27374aE0d66f3E91ED64c918BccEF9e',
    earnContractAddress: '0x1226f2eBC27374aE0d66f3E91ED64c918BccEF9e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zappy-ftm-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Zappy',
    assets: ['FTM', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://www.zappy.finance/swap?from=0xc1be9a4d5d45beeacae296a7bd5fadbfc14602c4&to=tlos',
    addLiquidityUrl:
      'https://www.zappy.finance/liquidity/pool?main=0xc1be9a4d5d45beeacae296a7bd5fadbfc14602c4&base=tlos',
    createdAt: 1649183494,
  },
  {
    id: 'zappy-bnb-wtlos',
    name: 'BNB-WTLOS LP',
    token: 'BNB-WTLOS LP',
    tokenDescription: 'Zappy',
    tokenAddress: '0x65980727179035C6A10dC5d79057B842e893627c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'yhZapBnbWtlos',
    earnedTokenAddress: '0x8bF553291D2810C772ea43bBA77eDFf92191b450',
    earnContractAddress: '0x8bF553291D2810C772ea43bBA77eDFf92191b450',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zappy-bnb-wtlos',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Zappy',
    assets: ['BNB', 'WTLOS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://www.zappy.finance/swap?from=0x2c78f1b70ccf63cdee49f9233e9faa99d43aa07e&to=tlos',
    addLiquidityUrl:
      'https://www.zappy.finance/liquidity/pool?main=0x2c78f1b70ccf63cdee49f9233e9faa99d43aa07e&base=tlos',
    createdAt: 1649183494,
  },
];
