export const polygonPools = [
  {
    id: 'polygon-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy.Finance',
    tokenAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolygonBIFI',
    earnedTokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    earnContractAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy.Finance',
    assets: ['BIFI'],
    withdrawalFee: '0.05%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Maxi',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
    createdAt: 1621450869,
  },
  {
    id: 'sushi-jrt-eth',
    name: 'JRT-ETH SLP',
    token: 'JRT-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xa769c6786C3717945438d4C4feb8494a1a6Ca443',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiJRT-ETH',
    earnedTokenAddress: '0xfdE69969f4527343D78F9C9AC797ded29098B215',
    earnContractAddress: '0xfdE69969f4527343D78F9C9AC797ded29098B215',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-jrt-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['JRT', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x596ebe76e2db4470966ea395b0d063ac6197a8c5',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x596ebe76e2db4470966ea395b0d063ac6197a8c5',
    createdAt: 1634625658,
  },
  {
    id: 'jarvis-2sgd',
    logo: 'single-assets/2SGD.png',
    name: 'jSGD-XSGD',
    token: '2sgd',
    tokenDescription: 'Curve (Jarvis)',
    tokenAddress: '0xeF75E9C7097842AcC5D0869E1dB4e5fDdf4BFDDA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJarvis2sgd',
    earnedTokenAddress: '0x18DAdac6d0AAF37BaAAC811F6338427B46815a81',
    earnContractAddress: '0x18DAdac6d0AAF37BaAAC811F6338427B46815a81',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jarvis-2sgd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['jSGD', 'XSGD'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_NONE', 'MCAP_MICRO'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://polygon.curve.fi/factory/22/deposit',
    createdAt: 1647601851,
  },
  {
    id: 'quick-eth-fuse',
    name: 'FUSE-ETH LP',
    token: 'FUSE-ETH LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x9Ab6E8197cfD366F920b54cb069cE6273898CB0B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapFUSE-ETH',
    earnedTokenAddress: '0xba61Ae734e5348e83A75599Bc5a64CB6aAdC0247',
    earnContractAddress: '0xba61Ae734e5348e83A75599Bc5a64CB6aAdC0247',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-fuse',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['FUSE', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xF915fDDa4c882731C0456a4214548Cd13A822886',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xF915fDDa4c882731C0456a4214548Cd13A822886',
    createdAt: 1647440992,
  },
  {
    id: 'quick-usdc-orbs',
    name: 'ORBS-USDC LP',
    token: 'ORBS-USDC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xB2b6D423e535b57aaD06E9866803B95fB66152EA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapORBS-USDC',
    earnedTokenAddress: '0x333F81C94e388002aAc75ccF23Ab4865B52D7a10',
    earnContractAddress: '0x333F81C94e388002aAc75ccF23Ab4865B52D7a10',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-orbs',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ORBS', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x614389EaAE0A6821DC49062D56BDA3d9d45Fa2ff',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x614389EaAE0A6821DC49062D56BDA3d9d45Fa2ff',
    createdAt: 1647302895,
  },
  {
    id: 'sushi-weth-sx-eol',
    name: 'ETH-SX SLP',
    token: 'ETH-SX SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x1bF9805B40a5f69c7d0f9E5d1Ab718642203c652',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-SX',
    earnedTokenAddress: '0xD244A8494ec9fF6923D6Ec07f94096b87b166187',
    earnContractAddress: '0xD244A8494ec9fF6923D6Ec07f94096b87b166187',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-weth-sx',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SushiSwap',
    assets: ['SX', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79',
    createdAt: 1646331464,
  },
  {
    id: 'quick-eth-cxeth',
    name: 'ETH-cxETH LP',
    token: 'ETH-cxETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xda7cd765DF426fCA6FB5E1438c78581E4e66bFe7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-cxETH',
    earnedTokenAddress: '0x5027da71EA7679775C3870A508cB1CdEFDbECa25',
    earnContractAddress: '0x5027da71EA7679775C3870A508cB1CdEFDbECa25',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-cxeth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ETH', 'cxETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xfe4546feFe124F30788c4Cc1BB9AA6907A7987F9',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9',
    createdAt: 1645899124,
  },
  {
    id: 'quick-cxada-cxeth',
    name: 'cxADA-cxETH LP',
    token: 'cxADA-cxETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xfeC2385b26a4446A7813d16263348FdE7E99FEe4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickcxADA-cxETH',
    earnedTokenAddress: '0xFe4519d295089aC689a6EC6779De4740c06D4707',
    earnContractAddress: '0xFe4519d295089aC689a6EC6779De4740c06D4707',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cxada-cxeth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['cxADA', 'cxETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x64875Aaa68d1d5521666C67d692Ee0B926b08b2F/0xfe4546feFe124F30788c4Cc1BB9AA6907A7987F9',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x64875aaa68d1d5521666c67d692ee0b926b08b2f&outputCurrency=0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9',
    createdAt: 1645967761,
  },
  {
    id: 'quick-cxdoge-cxeth',
    name: 'cxDOGE-cxETH LP',
    token: 'cxDOGE-cxETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x96A523d3576b9b1DfEE49AA73723F64A5b553720',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickcxDOGE-cxETH',
    earnedTokenAddress: '0x87F322dD9c327B4F91A7D620EE6ED7FA883Af62f',
    earnContractAddress: '0x87F322dD9c327B4F91A7D620EE6ED7FA883Af62f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cxdoge-cxeth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['cxDOGE', 'cxETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x9Bd9aD490dD3a52f096D229af4483b94D63BE618/0xfe4546feFe124F30788c4Cc1BB9AA6907A7987F9',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x9bd9ad490dd3a52f096d229af4483b94d63be618&outputCurrency=0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9',
    createdAt: 1645982670,
  },
  {
    id: 'quick-kiro-matic',
    name: 'KIRO-MATIC LP',
    token: 'KIRO-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x3f245C6f18442Bd6198d964C567a01BD4202e290',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapKIRO-MATIC',
    earnedTokenAddress: '0x0A9e464D6939D37a78d7B2a32e8B8F75f15c3C25',
    earnContractAddress: '0x0A9e464D6939D37a78d7B2a32e8B8F75f15c3C25',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-kiro-matic',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['KIRO', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_LOW',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xB382C1cfA622795a534e5bd56Fac93d59BAc8B0D',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xB382C1cfA622795a534e5bd56Fac93d59BAc8B0D/ETH',
    createdAt: 1642000487,
  },
  {
    id: 'quick-genesis-quick',
    name: 'GENESIS-QUICK LP',
    token: 'GENESIS-QUICK LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xF0696be85fa54F7a8C9F20AA98aA4409CD5C9D1B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapGENESIS-QUICK',
    earnedTokenAddress: '0x775965D328FD46b51595E7d711c6B563CD4eFCF4',
    earnContractAddress: '0x775965D328FD46b51595E7d711c6B563CD4eFCF4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-genesis-quick',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['GENESIS', 'QUICK'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13&outputCurrency=0x51869836681BcE74a514625c856aFb697a013797',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0x51869836681BcE74a514625c856aFb697a013797',
    createdAt: 1645091761,
  },
  {
    id: 'quick-bico-eth',
    name: 'BICO-ETH LP',
    token: 'BICO-ETH LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x7E02A82a9F2b96eE2836c5C64c44e0aB13020f4b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapBICO-ETH',
    earnedTokenAddress: '0xEaa2Ce6351d803456235809D82357ED8D23E8502',
    earnContractAddress: '0xEaa2Ce6351d803456235809D82357ED8D23E8502',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-bico-eth',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['BICO', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x91c89A94567980f0e9723b487b0beD586eE96aa7',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x91c89A94567980f0e9723b487b0beD586eE96aa7',
    createdAt: 1645091761,
  },
  {
    id: 'quick-fodl-matic-eol',
    name: 'FODL-MATIC LP',
    token: 'FODL-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2Fc4DFCEe8C331D54341f5668a6d9BCdd86F8e2f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapFODL-MATIC',
    earnedTokenAddress: '0x2434826b2cA0BeEDc9287Fb592d94328F525eA0D',
    earnContractAddress: '0x2434826b2cA0BeEDc9287Fb592d94328F525eA0D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-fodl-matic',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['FODL', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0x5314bA045a459f63906Aa7C76d9F337DcB7d6995',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x5314bA045a459f63906Aa7C76d9F337DcB7d6995',
    createdAt: 1644238771,
  },
  {
    id: 'quick-quick-tel',
    name: 'QUICK-TEL LP',
    token: 'QUICK-TEL LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xE88e24F49338f974B528AcE10350Ac4576c5c8A1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapQUICK-TEL',
    earnedTokenAddress: '0x14dC157f8BeF165B557599744c40b605F29cB1A0',
    earnContractAddress: '0x14dC157f8BeF165B557599744c40b605F29cB1A0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-tel',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK', 'TEL'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_SMALL',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    createdAt: 1644239255,
  },
  {
    id: 'quick-bnb-usdc',
    name: 'BNB-USDC LP',
    token: 'BNB-USDC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x40A5Df3E37152d4DaF279e0450289Af76472b02e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapBNB-USDC',
    earnedTokenAddress: '0xbfd1a5525C47c229B9c0D82ca4B6567F9f74a3cC',
    earnContractAddress: '0xbfd1a5525C47c229B9c0D82ca4B6567F9f74a3cC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-bnb-usdc',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['BNB', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_LARGE',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x5c4b7CCBF908E64F32e12c6650ec0C96d717f03F',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x5c4b7CCBF908E64F32e12c6650ec0C96d717f03F',
    createdAt: 1644238131,
  },
  {
    id: 'quick-book-matic',
    name: 'BOOK-MATIC LP',
    token: 'BOOK-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xE6672538c35508b011b82C986B8822ECF26b1cbC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapBOOK-MATIC',
    earnedTokenAddress: '0x4E685C0d068cb0af88Ff662B89951Ad5B3727387',
    earnContractAddress: '0x4E685C0d068cb0af88Ff662B89951Ad5B3727387',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-book-matic',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['BOOK', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0x8192759Bf7f247cC92F74E39B3A4225516624fC1',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x8192759Bf7f247cC92F74E39B3A4225516624fC1',
    createdAt: 1644238407,
  },
  {
    id: 'sushi-eth-mana',
    name: 'ETH-MANA LP',
    token: 'ETH-MANA LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xc48AE82ca34C63887b975F20ABA91a38f2a900B8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-MANA',
    earnedTokenAddress: '0x18a20abeba0086ac0c564B2bA3a7BaF18568667D',
    earnContractAddress: '0x18a20abeba0086ac0c564B2bA3a7BaF18568667D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-mana',
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'MANA'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MEDIUM',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&inputCurrency=0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
    createdAt: 1643755752,
  },
  {
    id: 'quick-sand-matic-eol',
    name: 'SAND-MATIC LP',
    token: 'SAND-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x369582d2010B6eD950B571F4101e3bB9b554876F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapSAND-MATIC',
    earnedTokenAddress: '0x6d212Dd38aD0811d30AABf08fB78018133F8417E',
    earnContractAddress: '0x6d212Dd38aD0811d30AABf08fB78018133F8417E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-sand-matic',
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    retireReason: 'rewards',
    assets: ['SAND', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_LARGE',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683&inputCurrency=ETH',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683/ETH',
    createdAt: 1643011650,
  },
  {
    id: 'popsicle-ice-eth',
    name: 'ICE-ETH LP',
    token: 'ICE-ETH SLP',
    tokenDescription: 'SushiSwap (Popsicle)',
    tokenAddress: '0x941eb28e750C441AEF465a89E43DDfec2561830b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPopsicleICE-WETH',
    earnedTokenAddress: '0x2109361Ed6759296A70A3f025fBce9744E5BC406',
    earnContractAddress: '0x2109361Ed6759296A70A3f025fBce9744E5BC406',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'popsicle-ice-eth',
    depositsPaused: false,
    status: 'active',
    platform: 'Popsicle',
    assets: ['ICE', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'MCAP_MICRO',
      'BATTLE_TESTED',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0x4e1581f01046eFDd7a1a2CDB0F82cdd7F71F2E59',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x4e1581f01046eFDd7a1a2CDB0F82cdd7F71F2E59',
    createdAt: 1642630360,
  },
  {
    id: 'jarvis-2cad',
    logo: 'single-assets/2CAD.svg',
    name: 'jCAD-CADC',
    token: '2cad',
    tokenDescription: 'Curve (Jarvis)',
    tokenAddress: '0xA69b0D5c0C401BBA2d5162138613B5E38584F63F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJarvis2Cad',
    earnedTokenAddress: '0xcf9Dd1de1D02158B3d422779bd5184032674A6D1',
    earnContractAddress: '0xcf9Dd1de1D02158B3d422779bd5184032674A6D1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jarvis-2cad',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['jCAD', 'CADC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://polygon.curve.fi/factory/23/deposit',
    createdAt: 1642673471,
  },
  {
    id: 'quick-ustw-matic',
    name: 'USTw-MATIC LP',
    token: 'USTw-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x9b7e966fe005d616b5560E4BaA7cFA8747d6cBb9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUST-MATIC',
    earnedTokenAddress: '0x21280954C4F99747757076A0cC56ba594037075d',
    earnContractAddress: '0x21280954C4F99747757076A0cC56ba594037075d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-ustw-matic',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USTw', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xE6469Ba6D2fD6130788E0eA9C0a0515900563b59/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xE6469Ba6D2fD6130788E0eA9C0a0515900563b59&inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    createdAt: 1641937774,
  },
  {
    id: 'quick-psp-matic',
    name: 'PSP-MATIC LP',
    token: 'PSP-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x7AfC060acCA7ec6985d982dD85cC62B111CAc7a7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSwapPSP-MATIC',
    earnedTokenAddress: '0x051aBa342824Ba03519694774B4e9297618Fa818',
    earnContractAddress: '0x051aBa342824Ba03519694774B4e9297618Fa818',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-psp-matic',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['PSP', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0x42d61D766B85431666B39B89C43011f24451bFf6',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x42d61D766B85431666B39B89C43011f24451bFf6/ETH',
    createdAt: 1641848936,
  },
  {
    id: 'quick-gns-dai-eol',
    name: 'GNS-DAI LP',
    token: 'GNS-DAI LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x6E53cB6942e518376E9e763554dB1A45DDCd25c4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickGNS-DAI',
    earnedTokenAddress: '0x5E55f1f68c3a708C1A47a6FCFd6D0ADaEf7380d7',
    earnContractAddress: '0x5E55f1f68c3a708C1A47a6FCFd6D0ADaEf7380d7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-gns-dai',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'QuickSwap',
    assets: ['GNS', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'IL_HIGH',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063&outputCurrency=0xE5417Af564e4bFDA1c483642db72007871397896',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xE5417Af564e4bFDA1c483642db72007871397896/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    createdAt: 1641761360,
  },
  {
    id: 'jarvis-2jpy',
    logo: 'single-assets/2JPY.svg',
    name: 'jJPY-JPYC',
    token: '2jpy',
    tokenDescription: 'Curve (Jarvis)',
    tokenAddress: '0xE8dCeA7Fb2Baf7a9F4d9af608F06d78a687F8d9A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJarvis2jpy',
    earnedTokenAddress: '0x94F64bb5046Ee377bFBb664736547B7f78e5AE06',
    earnContractAddress: '0x94F64bb5046Ee377bFBb664736547B7f78e5AE06',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jarvis-2jpy',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['jJPY', 'JPYC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://polygon.curve.fi/factory/85/deposit',
    createdAt: 1641144218,
  },
  {
    id: 'jarvis-5eur',
    name: 'agEUR-4EUR',
    token: 'ag5eur',
    tokenDescription: 'Curve (Jarvis)',
    tokenAddress: '0x81212149b983602474fcD0943E202f38b38d7484',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJarvisag5eur',
    earnedTokenAddress: '0xf3Eac1e5A81A732b002691E1f96cE5A43BfBb929',
    earnContractAddress: '0xf3Eac1e5A81A732b002691E1f96cE5A43BfBb929',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jarvis-5eur',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['agEUR', '4EUR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://polygon.curve.fi/factory/72/deposit',
    createdAt: 1640691348,
  },
  {
    id: 'quick-tomb-mimatic-eol',
    name: 'TOMB-MAI LP',
    token: 'TOMB-MAI LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xbDBE9c09fFC1dE53AD9FA3732eC1cD37Da7Ba52F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickTOMB-MAI',
    earnedTokenAddress: '0x0b568c995aCDb8a78570220F104c256f9075A4b6',
    earnContractAddress: '0x0b568c995aCDb8a78570220F104c256f9075A4b6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-tomb-mimatic',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['TOMB', 'MAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LOW',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1/0x0e98C977B943f06075b2D795794238fBfB9b9a34',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1&inputCurrency=0x0e98C977B943f06075b2D795794238fBfB9b9a34',
    createdAt: 1640281502,
  },
  {
    id: 'quick-hbar[0x]-mimatic-eol',
    name: 'HBAR-MAI LP',
    token: 'HBAR-MAI LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x71952D09Aa093aCCCAe0c1d5612D7FE26F20517f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickHBAR-MAI',
    earnedTokenAddress: '0x1b90eC99d3B99086691EC3CBEf1F1407E2753e38',
    earnContractAddress: '0x1b90eC99d3B99086691EC3CBEf1F1407E2753e38',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-hbar[0x]-mimatic',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['HBAR', 'MAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1/0x1646C835d70F76D9030DF6BaAeec8f65c250353d',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1&inputCurrency=0x1646C835d70F76D9030DF6BaAeec8f65c250353d',
    createdAt: 1640281632,
  },
  {
    id: 'quick-mimatic-clam-eol',
    name: 'CLAM-MAI LP',
    token: 'CLAM-MAI LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x1581802317f32A2665005109444233ca6E3e2D68',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCLAM-MAI',
    earnedTokenAddress: '0xa2A2A464E4c59cA64F9FC85DF96b3C604EAf2d66',
    earnContractAddress: '0xa2A2A464E4c59cA64F9FC85DF96b3C604EAf2d66',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-mimatic-clam',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['CLAM', 'MAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1/0xC250e9987A032ACAC293d838726C511E6E1C029d',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1&inputCurrency=0xC250e9987A032ACAC293d838726C511E6E1C029d',
    createdAt: 1640281264,
  },
  {
    id: 'sushi-bct-klima-eol',
    name: 'BCT-KLIMA SLP',
    token: 'BCT-KLIMA SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x9803c7aE526049210a1725F7487AF26fE2c24614',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiBCT-KLIMA',
    earnedTokenAddress: '0x19AfD39F0f8A2deeD37Ed74F6a126b68432D4bc7',
    earnContractAddress: '0x19AfD39F0f8A2deeD37Ed74F6a126b68432D4bc7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-bct-klima',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SushiSwap',
    assets: ['BCT', 'KLIMA'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x4e78011Ce80ee02d2c3e649Fb657E45898257815/0x2F800Db0fdb5223b3C3f354886d907A671414A7F',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x4e78011Ce80ee02d2c3e649Fb657E45898257815&outputCurrency=0x2F800Db0fdb5223b3C3f354886d907A671414A7F',
    createdAt: 1640136296,
  },
  {
    id: 'sushi-usdc-bct',
    name: 'BCT-USDC SLP',
    token: 'BCT-USDC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x1E67124681b402064CD0ABE8ed1B5c79D2e02f64',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-BCT',
    earnedTokenAddress: '0x90A7289A3aAb4b070A2646DCa757025Ee84cF580',
    earnContractAddress: '0x90A7289A3aAb4b070A2646DCa757025Ee84cF580',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-bct',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['BCT', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x2F800Db0fdb5223b3C3f354886d907A671414A7F',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x2F800Db0fdb5223b3C3f354886d907A671414A7F',
    createdAt: 1640137462,
  },
  {
    id: 'sushi-eth-gohm',
    name: 'gOHM-ETH SLP',
    token: 'gOHM-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x1549E0E8127D380080aab448b82d280433CE4030',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-gOHM',
    earnedTokenAddress: '0xf49cB3B72d170407AeD7ED5B34d6e7bbE58Ac917',
    earnContractAddress: '0xf49cB3B72d170407AeD7ED5B34d6e7bbE58Ac917',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-gohm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['gOHM', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x1a3acf6D19267E2d3e7f898f42803e90C9219062/0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89&outputCurrency=0x1a3acf6D19267E2d3e7f898f42803e90C9219062',
    createdAt: 1639747398,
  },
  {
    id: 'sushi-usdc-tusd-eol',
    name: 'TUSD-USDC SLP',
    token: 'TUSD-USDC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x4AB66a7138F50Db9B7aDaF8CE8aFB3F638B22f7f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-TUSD',
    earnedTokenAddress: '0x1158785A1443642a70B9BC19c2f7491fAb3deaed',
    earnContractAddress: '0x1158785A1443642a70B9BC19c2f7491fAb3deaed',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-tusd',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SushiSwap',
    assets: ['TUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x2e1AD108fF1D8C782fcBbB89AAd783aC49586756',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x2e1AD108fF1D8C782fcBbB89AAd783aC49586756',
    createdAt: 1639337118,
  },
  {
    id: 'sushi-eth2x-eth',
    name: 'ETH2x-ETH SLP',
    token: 'ETH2x-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xadd053A09F95E565B25d8b9B84D0fcFaa962465E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH2x-ETH',
    earnedTokenAddress: '0x6E1D1Eb4581E4C612240Ec603a243ce9AE3265E4',
    earnContractAddress: '0x6E1D1Eb4581E4C612240Ec603a243ce9AE3265E4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth2x-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH2x', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x3Ad707dA309f3845cd602059901E39C4dcd66473/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x3Ad707dA309f3845cd602059901E39C4dcd66473',
    createdAt: 1639689694,
  },
  {
    id: 'sushi-fxs-frax',
    name: 'FXS-FRAX SLP',
    token: 'FXS-FRAX SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xDf45B5B68d9dC84173DD963c763AeA8CAD3E24A6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiFXS-FRAX',
    earnedTokenAddress: '0xe62a0a25a0eCE622f795d5f1Be0F374485B64bf8',
    earnContractAddress: '0xe62a0a25a0eCE622f795d5f1Be0F374485B64bf8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-fxs-frax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['FXS', 'FRAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x1a3acf6D19267E2d3e7f898f42803e90C9219062/0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89&outputCurrency=0x1a3acf6D19267E2d3e7f898f42803e90C9219062',
    createdAt: 1639691972,
  },
  {
    id: 'jarvis-4eur',
    logo: 'single-assets/4EUR.png',
    name: 'jEUR/PAR/EURS/EURt',
    token: 'crv4eur',
    tokenDescription: 'Curve (Jarvis)',
    tokenAddress: '0xAd326c253A84e9805559b73A08724e11E49ca651',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJarvis4eur',
    earnedTokenAddress: '0x80dAd30b61b6110aB4112e440988DA2d9aa85329',
    earnContractAddress: '0x80dAd30b61b6110aB4112e440988DA2d9aa85329',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jarvis-4eur',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['jEUR', 'PAR', 'EURS', 'EURt'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://polygon.curve.fi/factory/37/deposit',
    createdAt: 1639418113,
  },
  {
    id: 'curve-poly-eurt',
    logo: 'single-assets/EURt.png',
    name: 'EURt/DAI/USDC/USDT',
    token: 'crvEURT',
    tokenDescription: 'Curve',
    tokenAddress: '0x600743B1d8A96438bD46836fD34977a00293f6Aa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveEURt',
    earnedTokenAddress: '0x108c7a293162Adff86DA216AB5F91e56723125dc',
    earnContractAddress: '0x108c7a293162Adff86DA216AB5F91e56723125dc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-poly-eurt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['EURt', 'DAI', 'USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://polygon.curve.fi/eurtusd/deposit',
    createdAt: 1637751154,
  },
  {
    id: 'kyber-usdc-jeur',
    name: 'jEUR-USDC LP',
    token: 'jEUR-USDC LP',
    tokenDescription: 'Kyber (Jarvis)',
    tokenAddress: '0xa1219DBE76eEcBf7571Fed6b020Dd9154396B70e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKyberUSDC-jEUR',
    earnedTokenAddress: '0x4A041eD4dda1cf460D557E1bdCBf0b29c02A1b3c',
    earnContractAddress: '0x4A041eD4dda1cf460D557E1bdCBf0b29c02A1b3c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kyber-usdc-jeur',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kyber',
    assets: ['jEUR', 'USDC'],
    withdrawalFee: '0%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://dmm.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c/0xa1219dbe76eecbf7571fed6b020dd9154396b70e',
    buyTokenUrl:
      'https://dmm.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c',
    createdAt: 1633957002,
  },
  {
    id: 'curve-am3crv',
    logo: 'polygon/CURVE-3Pool.png',
    name: 'DAI/USDC/USDT',
    token: 'am3CRV',
    tokenDescription: 'Curve',
    tokenAddress: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveAm3CRV',
    earnedTokenAddress: '0xAA7C2879DaF8034722A0977f13c343aF0883E92e',
    earnContractAddress: '0xAA7C2879DaF8034722A0977f13c343aF0883E92e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-am3crv',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['DAI', 'USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://polygon.curve.fi/aave/deposit',
    createdAt: 1621952618,
  },
  {
    id: 'quick-matic-wcro',
    name: 'WCRO-MATIC LP',
    token: 'WCRO-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xfD168748dD07a32A401E800240aEC8EC6EFc706f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickWCRO-MATIC',
    earnedTokenAddress: '0x6EfBc871323148d9Fc34226594e90d9Ce2de3da3',
    earnContractAddress: '0x6EfBc871323148d9Fc34226594e90d9Ce2de3da3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-matic-wcro',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['CRO', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xf2D8124b8F9267DaD61351c7aD252362880C6638',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xf2D8124b8F9267DaD61351c7aD252362880C6638&inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    createdAt: 1638099468,
  },
  {
    id: 'quick-matic-mana-eol',
    name: 'MANA-MATIC LP',
    token: 'MANA-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x6b0Ce31eAD9b14c2281D80A5DDE903AB0855313A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickMANA-MATIC',
    earnedTokenAddress: '0x5e03C75a8728a8E0FF0326baADC95433009424d6',
    earnContractAddress: '0x5e03C75a8728a8E0FF0326baADC95433009424d6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-matic-mana',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['MANA', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4&inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    createdAt: 1638101634,
  },
  {
    id: 'quick-shib-matic-eol',
    name: 'SHIB-MATIC LP',
    token: 'SHIB-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5FB641De2663e8a94C9dea0a539817850d996e99',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSHIB-MATIC',
    earnedTokenAddress: '0x72B5Cf05770C9a6A99FB8652825884ee36a4BfdA',
    earnContractAddress: '0x72B5Cf05770C9a6A99FB8652825884ee36a4BfdA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-shib-matic',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['SHIB', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec&inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    createdAt: 1635166901,
  },
  {
    id: 'quick-dpi-eth-eol',
    name: 'DPI-ETH LP',
    token: 'DPI-ETH LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x9F77Ef7175032867d26E75D2fA267A6299E3fb57',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDPI-ETH',
    earnedTokenAddress: '0x1a83915207c9028a9f71e7D9Acf41eD2beB6f42D',
    earnContractAddress: '0x1a83915207c9028a9f71e7D9Acf41eD2beB6f42D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-dpi-eth',
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['DPI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&inputCurrency=0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369',
    createdAt: 1635166761,
  },
  {
    id: 'quick-ftm-matic',
    name: 'FTM-MATIC LP',
    token: 'FTM-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xD2B61a42d3790533fEDC2829951a65120624034a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickFTM-MATIC',
    earnedTokenAddress: '0x48e58c7E8d2063ae7ADe8a0829E00780155232eC',
    earnContractAddress: '0x48e58c7E8d2063ae7ADe8a0829E00780155232eC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-ftm-matic',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['FTM', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&inputCurrency=0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5',
    createdAt: 1635166165,
  },
  {
    id: 'quick-quick',
    logo: 'single-assets/QUICK.png',
    name: 'QUICK',
    token: 'QUICK',
    tokenDescription: 'QuickSwap (DragonLair)',
    tokenAddress: '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuick',
    earnedTokenAddress: '0x659418cc3cf755F5367a51aDb586a7F770Da6d29',
    earnContractAddress: '0x659418cc3cf755F5367a51aDb586a7F770Da6d29',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'QUICK',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK'],
    risks: [
      'COMPLEXITY_HIGH',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    createdAt: 1630505284,
  },
  {
    id: 'sushi-usdc-bifi',
    name: 'BIFI-USDC SLP',
    token: 'BIFI-USDC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x180237bd326d5245D0898336F54b3c8012c5c62f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-BIFI',
    earnedTokenAddress: '0x03F69AAF4c8512f533Da46cC9eFd49C4969e3CB8',
    earnContractAddress: '0x03F69AAF4c8512f533Da46cC9eFd49C4969e3CB8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-bifi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'BIFI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xFbdd194376de19a88118e84E279b977f165d01b8',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xFbdd194376de19a88118e84E279b977f165d01b8',
    createdAt: 1623949163,
  },
  {
    id: 'ape-wmatic-crystl-eol',
    name: 'CRYSTL-WMATIC LP',
    token: 'CRYSTL-WMATIC LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xB8e54c9Ea1616beEBe11505a419DD8dF1000E02a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapWMATIC-CRYSTL',
    earnedTokenAddress: '0x5Da31E310bb83e89BF85A4100A98C7b33530395c',
    earnContractAddress: '0x5Da31E310bb83e89BF85A4100A98C7b33530395c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-wmatic-crystl',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'ApeSwap',
    assets: ['CRYSTL', 'MATIC'],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x76bF0C28e604CC3fE9967c83b3C3F31c213cfE64',
    buyTokenUrl: 'https://app.apeswap.finance/swap',
    createdAt: 1634055850,
    retiredReason: 'tvl',
  },
  {
    id: 'kyber-usdc-jgbp',
    name: 'jGBP-USDC LP',
    token: 'jGBP-USDC LP',
    tokenDescription: 'Kyber (Jarvis)',
    tokenAddress: '0xbb2d00675B775E0F8acd590e08DA081B2a36D3a6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKyberUSDC-jGBP',
    earnedTokenAddress: '0xF10c108670b137Ae78C5705C84A6D00a9DD005a2',
    earnContractAddress: '0xF10c108670b137Ae78C5705C84A6D00a9DD005a2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kyber-usdc-jgbp',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kyber',
    assets: ['jGBP', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://dmm.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x767058f11800fba6a682e73a6e79ec5eb74fac8c/0xbb2d00675b775e0f8acd590e08da081b2a36d3a6',
    buyTokenUrl:
      'https://dmm.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x767058f11800fba6a682e73a6e79ec5eb74fac8c',
    createdAt: 1634040443,
  },
  {
    id: 'kyber-usdc-jchf',
    name: 'jCHF-USDC LP',
    token: 'jCHF-USDC LP',
    tokenDescription: 'Kyber (Jarvis)',
    tokenAddress: '0x439E6A13a5ce7FdCA2CC03bF31Fb631b3f5EF157',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKyberUSDC-jCHF',
    earnedTokenAddress: '0x2e78D4Cc7e1dF9845B60A28980b28B3E9fc82fd1',
    earnContractAddress: '0x2e78D4Cc7e1dF9845B60A28980b28B3E9fc82fd1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kyber-usdc-jchf',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kyber',
    assets: ['jCHF', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://dmm.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xbd1463f02f61676d53fd183c2b19282bff93d099/0x439e6a13a5ce7fdca2cc03bf31fb631b3f5ef157',
    buyTokenUrl:
      'https://dmm.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xbd1463f02f61676d53fd183c2b19282bff93d099',
    createdAt: 1634042173,
  },
  {
    id: 'curve-poly-ren',
    logo: 'single-assets/renBTC.png',
    name: 'WBTC/renBTC',
    token: 'btcCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0xf8a57c1d3b9629b77b6726a042ca48990A84Fb49',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveRen',
    earnedTokenAddress: '0x8c9d3Bc4425773BD2F00C4a2aC105c5Ad73c8141',
    earnContractAddress: '0x8c9d3Bc4425773BD2F00C4a2aC105c5Ad73c8141',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-poly-ren',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['WBTC', 'renBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://polygon.curve.fi/ren/deposit',
    createdAt: 1625136893,
  },
  {
    id: 'polysage-usdc-sage-eol',
    name: 'SAGE-USDC LP',
    token: 'SAGE-USDC LP',
    tokenDescription: 'Quickswap (PolySage)',
    tokenAddress: '0x62F089467e22de4bc1FB5EE605eDe7e782b76B29',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolysageQuickUSDC-SAGE',
    earnedTokenAddress: '0x614A3f93dE94fA900BB88Ab2b23B3856518505Fa',
    earnContractAddress: '0x614A3f93dE94fA900BB88Ab2b23B3856518505Fa',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polysage-usdc-sage',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['SAGE', 'USDC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x2ed945Dc703D85c80225d95ABDe41cdeE14e1992',
    buyTokenUrl: 'https://quickswap.exchange/#/swap',
    createdAt: 1633808799,
  },
  {
    id: 'polysage-wmatic-sage-eol',
    name: 'SAGE-MATIC LP',
    token: 'SAGE-MATIC LP',
    tokenDescription: 'Quickswap (PolySage)',
    tokenAddress: '0x70c096187F8a9ac03E34a0e7ed41bCd516878158',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolysageQuickWMATIC-SAGE',
    earnedTokenAddress: '0xC8e3dee8d07C06568D62fcDBBD1bFA8c7fb9ae17',
    earnContractAddress: '0xC8e3dee8d07C06568D62fcDBBD1bFA8c7fb9ae17',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polysage-wmatic-sage',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['SAGE', 'MATIC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x2ed945Dc703D85c80225d95ABDe41cdeE14e1992',
    buyTokenUrl: 'https://quickswap.exchange/#/swap',
    createdAt: 1633808533,
  },
  {
    id: 'cafe-pbrew-matic-eol',
    name: 'pBREW-MATIC LP',
    token: 'pBREW-MATIC LP',
    tokenDescription: 'CafeSwap',
    tokenAddress: '0x4f03233c742Ea20e73b46d5E33242b7770274ab8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCafeSwapBREW-MATIC',
    earnedTokenAddress: '0x700504eaBA64Cd92080059a3C93b6baa73a4Ec38',
    earnContractAddress: '0x700504eaBA64Cd92080059a3C93b6baa73a4Ec38',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cafe-pbrew-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['pBREW', 'MATIC'],
    addLiquidityUrl:
      'https://polygondex.cafeswap.finance/#/add/ETH/0xb5106a3277718ecad2f20ab6b86ce0fee7a21f09',
    buyTokenUrl:
      'https://polygondex.cafeswap.finance/#/swap?outputCurrency=0xb5106a3277718ecad2f20ab6b86ce0fee7a21f09',
    createdAt: 1633695157,
    retiredReason: 'tvl',
  },
  {
    id: 'cafe-pbrew-usdc-eol',
    name: 'pBREW-USDC LP',
    token: 'pBREW-USDC LP',
    tokenDescription: 'CafeSwap',
    tokenAddress: '0x723e866989Cacc8EFC346e1DCf5a9D73572bCFc8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCafeSwapBREW-USDC',
    earnedTokenAddress: '0xd7C196571068EC51b86F378eF19387E6f3885748',
    earnContractAddress: '0xd7C196571068EC51b86F378eF19387E6f3885748',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cafe-pbrew-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['pBREW', 'USDC'],
    addLiquidityUrl:
      'https://polygondex.cafeswap.finance/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xb5106a3277718ecad2f20ab6b86ce0fee7a21f09',
    buyTokenUrl:
      'https://polygondex.cafeswap.finance/#/swap?outputCurrency=0xb5106a3277718ecad2f20ab6b86ce0fee7a21f09',
    createdAt: 1633695451,
    retiredReason: 'tvl',
  },
  {
    id: 'sing-usdc-sing-eol',
    name: 'SING-USDC LP',
    token: 'SING-USDC ALP',
    tokenDescription: 'ApeSwap (Singular)',
    tokenAddress: '0x6046eB198AbC5Ea4f17027bC00a2aeE0420E84EE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSingUSDC-SING',
    earnedTokenAddress: '0xadfff816820B0f5C7255373DAca83489367bEB43',
    earnContractAddress: '0xadfff816820B0f5C7255373DAca83489367bEB43',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sing-usdc-sing',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['SING', 'USDC'],
    addLiquidityUrl:
      'https://app.apeswap.finance/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xCB898b0eFb084Df14dd8E018dA37B4d0f06aB26D',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0xCB898b0eFb084Df14dd8E018dA37B4d0f06aB26D',
    createdAt: 1633615462,
    retiredReason: 'tvl',
  },
  {
    id: 'ape-banana-matic',
    name: 'BANANA-MATIC ALP',
    token: 'BANANA-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x034293F21F1cCE5908BC605CE5850dF2b1059aC0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapBANANA-MATIC',
    earnedTokenAddress: '0xADA7F98fb2594E76914EB593e74B348A498Ea5Bd',
    earnContractAddress: '0xADA7F98fb2594E76914EB593e74B348A498Ea5Bd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-banana-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
    createdAt: 1624340191,
  },
  {
    id: 'curve-poly-atricrypto3',
    logo: 'uncategorized/ATRICRYPTO.png',
    name: 'aTriCrypto3',
    token: 'crvUSDBTCETH3',
    tokenDescription: 'Curve',
    tokenAddress: '0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveATriCrypto3',
    earnedTokenAddress: '0x5A0801BAd20B6c62d86C566ca90688A6b9ea1d3f',
    earnContractAddress: '0x5A0801BAd20B6c62d86C566ca90688A6b9ea1d3f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-poly-atricrypto3',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['DAI', 'USDC', 'USDT', 'WBTC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://polygon.curve.fi/atricrypto3/deposit',
    createdAt: 1630045733,
  },
  {
    id: 'sushi-fox-eth-eol',
    name: 'FOX-ETH SLP',
    token: 'FOX-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x93eF615F1DdD27d0E141Ad7192623A5c45e8f200',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiFOX-WETH',
    earnedTokenAddress: '0x4f589d7A5d816b436318F9634935Ff4755d7d8f5',
    earnContractAddress: '0x4f589d7A5d816b436318F9634935Ff4755d7d8f5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-fox-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SushiSwap',
    assets: ['FOX', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x65a05db8322701724c197af82c9cae41195b0aa8',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x65a05db8322701724c197af82c9cae41195b0aa8',
    createdAt: 1633700993,
    retiredReason: 'tvl',
  },
  {
    id: 'sushi-wmatic-ghst-eol',
    name: 'GHST-MATIC SLP',
    token: 'GHST-MATIC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xf69e93771F11AECd8E554aA165C3Fe7fd811530c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiWMATIC-GHST',
    earnedTokenAddress: '0x756866Bd84A2441664e80367977357b1F9b5F3D3',
    earnContractAddress: '0x756866Bd84A2441664e80367977357b1F9b5F3D3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-wmatic-ghst',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SushiSwap',
    assets: ['GHST', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
    createdAt: 1632922881,
  },
  {
    id: 'tetu-matic-tetu-eol',
    name: 'TETU-MATIC LP',
    token: 'TETU-MATIC LP',
    tokenDescription: 'SushiSwap (TETU)',
    tokenAddress: '0x34dA30D1f8Ef9799F5Ae3B9989e1dEf926052e78',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTetuWMATIC-TETU',
    earnedTokenAddress: '0xFbc896588413cEAb25fE80bb9D5322E577Af9536',
    earnContractAddress: '0xFbc896588413cEAb25fE80bb9D5322E577Af9536',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tetu-matic-tetu',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['TETU', 'MATIC'],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x255707B70BF90aa112006E1b07B9AeA6De021424',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0x255707B70BF90aa112006E1b07B9AeA6De021424',
    createdAt: 1632819589,
  },
  {
    id: 'polywise-usdc-wise-eol',
    name: 'WISE-USDC LP',
    token: 'WISE-USDC LP',
    tokenDescription: 'QuickSwap (Polywise)',
    tokenAddress: '0x2F9209Ef6fA6C002bf6fC99124336e24F88B62D0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolywiseUSDC-WISE',
    earnedTokenAddress: '0x15D0841956242105aE614763E3fbDc60112E23a9',
    earnContractAddress: '0x15D0841956242105aE614763E3fbDc60112E23a9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polywise-usdc-wise',
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['polyWISE', 'USDC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x4c19DdeebAF84cA3A255730295AD9d824D4Ff51f/0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x4c19DdeebAF84cA3A255730295AD9d824D4Ff51f&outputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    createdAt: 1632516813,
  },
  {
    id: 'polywise-wmatic-wise-eol',
    name: 'WISE-MATIC LP',
    token: 'WISE-MATIC LP',
    tokenDescription: 'QuickSwap (Polywise)',
    tokenAddress: '0x89d97d96fA57A83e285a5D1709Fc5345C8e92d94',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolywiseWMATIC-WISE',
    earnedTokenAddress: '0x9CE8a8F957f8f9Dfa787B130200dA749913Ed967',
    earnContractAddress: '0x9CE8a8F957f8f9Dfa787B130200dA749913Ed967',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polywise-wmatic-wise',
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['polyWISE', 'MATIC'],
    withdrawalFee: '0.0%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x4c19DdeebAF84cA3A255730295AD9d824D4Ff51f/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x4c19DdeebAF84cA3A255730295AD9d824D4Ff51f&outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    createdAt: 1632511554,
  },
  {
    id: 'polyalpha-alpha-matic-eol',
    name: 'ALPHA-MATIC LP',
    token: 'ALPHA-MATIC LP',
    tokenDescription: 'QuickSwap (PolyAlpha)',
    tokenAddress: '0x79b368B3Aa34fF2044F7869024b5367A284D3b0E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyAlphaALPHA-MATIC',
    earnedTokenAddress: '0xa1E625D1e3b44468EB2a35d39d95f724c70ecd41',
    earnContractAddress: '0xa1E625D1e3b44468EB2a35d39d95f724c70ecd41',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyalpha-alpha-matic',
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['ALPHA', 'MATIC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x0B048D6e01a6b9002C291060bF2179938fd8264c',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x0B048D6e01a6b9002C291060bF2179938fd8264c',
    createdAt: 1632219035,
  },
  {
    id: 'polyalpha-alpha-usdc-eol',
    name: 'ALPHA-USDC LP',
    token: 'ALPHA-USDC LP',
    tokenDescription: 'QuickSwap (JetSwap)',
    tokenAddress: '0x944a8B12D898E878F92cfC18eee5E6f3f7e2E70d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyAlphaALPHA-USDC',
    earnedTokenAddress: '0xF274B7aDddC8DA615a8bACf6434816cD58B5b05D',
    earnContractAddress: '0xF274B7aDddC8DA615a8bACf6434816cD58B5b05D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyalpha-alpha-usdc',
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['ALPHA', 'USDC'],
    addLiquidityUrl:
      'https://polygon-exchange.jetswap.finance/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x0B048D6e01a6b9002C291060bF2179938fd8264c',
    buyTokenUrl:
      'https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0x0B048D6e01a6b9002C291060bF2179938fd8264c',
    createdAt: 1632219357,
  },
  {
    id: 'sandman-delirium-matic-eol',
    name: 'DELIRIUM-MATIC LP',
    token: 'DELIRIUM-MATIC LP',
    tokenDescription: 'QuickSwap (Sandman)',
    tokenAddress: '0x6E01a7E55987Bc195b54FBbdaAf40e57544b23C3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSandmanDELIRIUM-MATIC',
    earnedTokenAddress: '0x5675d63494EDDa594727033864F1bca375736191',
    earnContractAddress: '0x5675d63494EDDa594727033864F1bca375736191',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sandman-delirium-matic',
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['DELIRIUM', 'MATIC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    createdAt: 1632157998,
  },
  {
    id: 'sandman-delirium-usdc-eol',
    name: 'DELIRIUM-USDC LP',
    token: 'DELIRIUM-USDC LP',
    tokenDescription: 'QuickSwap (Sandman)',
    tokenAddress: '0x2F051dB1634eEa2081590860ab8E756D452f2239',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSandmanDELIRIUM-USDC',
    earnedTokenAddress: '0xF082954596D3deB61D80dF135663C0fbDe4F7C37',
    earnContractAddress: '0xF082954596D3deB61D80dF135663C0fbDe4F7C37',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sandman-delirium-usdc',
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['DELIRIUM', 'USDC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    createdAt: 1632157642,
  },
  {
    id: 'mai-qi-matic',
    name: 'QI-MATIC LP',
    token: 'QI-MATIC QLP',
    tokenDescription: 'QuickSwap (Mai)',
    tokenAddress: '0x9A8b2601760814019B7E6eE0052E25f1C623D1E6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMaiQI-MATIC',
    earnedTokenAddress: '0x944A5C12cD4399ABC6883fF1ba40A14c23B2Fd37',
    earnContractAddress: '0x944A5C12cD4399ABC6883fF1ba40A14c23B2Fd37',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mai-qi-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mai',
    assets: ['QI', 'MATIC'],
    depositFee: '0.5%',
    withdrawalFee: '0.0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x580A84C73811E1839F75d86d75d88cCa0c241fF4/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
    createdAt: 1631621928,
  },
  {
    id: 'quick-qi-eth-eol',
    name: 'QI-ETH LP',
    token: 'QI-ETH LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x8C1b40Ea78081B70F661C3286c74E71b4602C9C0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQI-ETH',
    earnedTokenAddress: '0x8c864B1FD2BbB20F614661ddD992eFaeEeF0b2Ac',
    earnContractAddress: '0x8c864B1FD2BbB20F614661ddD992eFaeEeF0b2Ac',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-qi-eth',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['QI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x580A84C73811E1839F75d86d75d88cCa0c241fF4/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
    createdAt: 1631186885,
  },
  {
    id: 'quick-mai-usdt',
    name: 'MAI-USDT LP',
    token: 'MAI-USDT LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xE89faE1B4AdA2c869f05a0C96C87022DaDC7709a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickMAI-USDT',
    earnedTokenAddress: '0xe5844a9Af7748492dAba745506bfB2b91f19be62',
    earnContractAddress: '0xe5844a9Af7748492dAba745506bfB2b91f19be62',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-mai-usdt',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['MAI', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xc2132D05D31c914a87C6611C10748AEb04B58e8F/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    createdAt: 1631184021,
  },
  {
    id: 'quick-dai-mai',
    name: 'MAI-DAI LP',
    token: 'MAI-DAI LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x74214F5d8AA71b8dc921D8A963a1Ba3605050781',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickMAI-DAI',
    earnedTokenAddress: '0x64fbCDfd1335AfdC8f81383919483c593399c738',
    earnContractAddress: '0x64fbCDfd1335AfdC8f81383919483c593399c738',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-dai-mai',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['MAI', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    createdAt: 1631181877,
  },
  {
    id: 'quick-mai-matic-eol',
    name: 'MAI-MATIC LP',
    token: 'MAI-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x7805B64e2d99412d3b8F10Dfe8fc55217C5cc954',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickMAI-MATIC',
    earnedTokenAddress: '0xD6eB31D849eE79B5F5fA1b7c470cDDFa515965cD',
    earnContractAddress: '0xD6eB31D849eE79B5F5fA1b7c470cDDFa515965cD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-mai-matic',
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['MAI', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    createdAt: 1630874617,
  },
  {
    id: 'quick-usdt-matic',
    name: 'USDT-MATIC LP',
    token: 'USDT-MATIC LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0x604229c960e5CACF2aaEAc8Be68Ac07BA9dF81c3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDT-MATIC',
    earnedTokenAddress: '0x7c0E28652523e36f0dF89C5A895cF59D493cb04c',
    earnContractAddress: '0x7c0E28652523e36f0dF89C5A895cF59D493cb04c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdt-matic',
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDT', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    createdAt: 1630873083,
  },
  {
    id: 'wexpoly-usdc-wusd-eol',
    name: 'USDC-WUSD LP',
    token: 'USDC-WUSD WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x7B93196CD4861f345509c2da31058CA4B1c30D63',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyUSDC-WUSD',
    earnedTokenAddress: '0x955EAC48a81D8a8A312884A022D806015E901CDE',
    earnContractAddress: '0x955EAC48a81D8a8A312884A022D806015E901CDE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-usdc-wusd',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['USDC', 'WUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xb8ab048D6744a276b2772dC81e406a4b769A5c3D',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xb8ab048D6744a276b2772dC81e406a4b769A5c3D',
    createdAt: 1630750371,
  },
  {
    id: 'ape-watch-matic',
    name: 'WATCH-MATIC LP',
    token: 'WATCH-MATIC LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xf67DE5Cf1fB01DC4df842a846Df2a7Ec07c41b93',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeWATCH-MATIC',
    earnedTokenAddress: '0xb2A56dFdbC890c102Dcd5e30afcfB43cD3343762',
    earnContractAddress: '0xb2A56dFdbC890c102Dcd5e30afcfB43cD3343762',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-watch-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['WATCH', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/0x09211dc67f9fe98fb7bbb91be0ef05f4a12fa2b2/ETH',
    buyTokenUrl:
      'https://app.apeswap.finance/swap?outputCurrency=0x09211Dc67f9fe98Fb7bBB91Be0ef05f4a12FA2b2',
    createdAt: 1630476350,
  },
  {
    id: 'wexpoly-usdc-wex-eol',
    name: 'USDC-WEXpoly LP',
    token: 'USDC-WEXpoly WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x5DE6a3CcA10d3F788EEdbD4923e31D4658bf6f9a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyUSDC-WexPoly',
    earnedTokenAddress: '0xc58475e5385b056D29ab455F40634450a613e789',
    earnContractAddress: '0xc58475e5385b056D29ab455F40634450a613e789',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-usdc-wex',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['WEXpoly', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    createdAt: 1630426679,
  },
  {
    id: 'sushi-axs-eth',
    name: 'AXS-ETH SLP',
    token: 'AXS-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x7BA331A8b360F4c31E6014aBdd6852F92Fb21557',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiAXS-ETH',
    earnedTokenAddress: '0x3404361876897A92579DcEBd9eDC94A1662dC4aF',
    earnContractAddress: '0x3404361876897A92579DcEBd9eDC94A1662dC4aF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-axs-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['AXS', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x61BDD9C7d4dF4Bf47A4508c0c8245505F2Af5b7b',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x61BDD9C7d4dF4Bf47A4508c0c8245505F2Af5b7b',
    createdAt: 1630168878,
  },
  {
    id: 'ironswap-ice-iron-eol',
    name: 'ICE-IRON LP',
    token: 'ICE-IRON LP',
    tokenDescription: 'IronSwap (DFYN)',
    tokenAddress: '0x7e2cc09d3d36b3aF6EdfF55B214fd62885234E95',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSwapICE-IRON',
    earnedTokenAddress: '0xBD8B03a3fF5F63093000Df6De33B0A6C9E5bb501',
    earnContractAddress: '0xBD8B03a3fF5F63093000Df6De33B0A6C9E5bb501',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ironswap-ice-iron',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'IronFinance',
    assets: ['ironICE', 'IRON'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a&outputCurrency=0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef',
    createdAt: 1630075988,
    retiredReason: 'tvl',
  },
  {
    id: 'ape-usdc-dai',
    name: 'USDC-DAI ALP',
    token: 'USDC-DAI ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x5b13B583D4317aB15186Ed660A1E4C65C10da659',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapUSDC-DAI',
    earnedTokenAddress: '0x8440DAe4E1002e83D57fbFD6d33E6F3684a35036',
    earnContractAddress: '0x8440DAe4E1002e83D57fbFD6d33E6F3684a35036',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-usdc-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['USDC', 'DAI'],
    withdrawalFee: '0%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    createdAt: 1630064594,
  },
  {
    id: 'sushi-avax-weth',
    name: 'AVAX-ETH SLP',
    token: 'AVAX-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x1274De0DE2e9D9b1d0E06313c0E5EdD01CC335eF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-AVAX',
    earnedTokenAddress: '0xf5881fF8fF609C1202E0c6481bc16B4273407A04',
    earnContractAddress: '0xf5881fF8fF609C1202E0c6481bc16B4273407A04',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-avax-weth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['AVAX', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b',
    createdAt: 1630000146,
  },
  {
    id: 'ironswap-iron',
    logo: 'uncategorized/IRON3USD.png',
    name: 'IRON/USDC/USDT/DAI',
    token: 'IRON-IS3USD',
    tokenDescription: 'IronSwap',
    tokenAddress: '0x985D40feDAA3208DAbaCDFDCA00CbeAAc9543949',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSwapIRON3USD',
    earnedTokenAddress: '0x9F94558306D37F23a85ac95fDcCee7551C086b34',
    earnContractAddress: '0x9F94558306D37F23a85ac95fDcCee7551C086b34',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ironswap-iron',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    assets: ['IRON', 'USDC', 'USDT', 'DAI'],
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://app.iron.finance/swap/pools/isiron/deposit',
    createdAt: 1629902239,
  },
  {
    id: 'quick-eth-rendoge-eol',
    name: 'ETH-renDOGE LP',
    token: 'ETH-renDOGE LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0xAB1403De66519B898b38028357B74DF394a54a37',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-RENDOGE',
    earnedTokenAddress: '0xC4FABD1E2A99c84b7fd152a41426B77c217c5764',
    earnContractAddress: '0xC4FABD1E2A99c84b7fd152a41426B77c217c5764',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-rendoge',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['ETH', 'renDOGE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xcE829A89d4A55a63418bcC43F00145adef0eDB8E',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xcE829A89d4A55a63418bcC43F00145adef0eDB8E',
    createdAt: 1629635082,
  },
  {
    id: 'polypup-ball-ball-eol',
    logo: 'single-assets/BALL.png',
    name: 'BALL',
    token: 'BALL',
    tokenDescription: 'Polypup',
    tokenAddress: '0x883aBe4168705d2e5dA925d28538B7a6AA9d8419',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupBallBALL',
    earnedTokenAddress: '0x48Fc8A7D72e5e54589F0B20928C98193c9133e70',
    earnContractAddress: '0x48Fc8A7D72e5e54589F0B20928C98193c9133e70',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BALL',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BALL'],
    withdrawalFee: '0.0%',
    retireReason: 'rewards',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x883aBe4168705d2e5dA925d28538B7a6AA9d8419',
    createdAt: 1629256207,
  },
  {
    id: 'polypup-ball-usdc-ball-eol',
    name: 'BALL-USDC QLP',
    token: 'BALL-USDC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0x0097692463fD591aEf388851FE2d756505f498AC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupBallUSDC-BALL',
    earnedTokenAddress: '0x1bCe9A53f3f71DD6B6551712a622E891cf05F477',
    earnContractAddress: '0x1bCe9A53f3f71DD6B6551712a622E891cf05F477',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-ball-usdc-ball',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BALL', 'USDC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x883aBe4168705d2e5dA925d28538B7a6AA9d8419',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x883aBe4168705d2e5dA925d28538B7a6AA9d8419',
    createdAt: 1629262245,
  },
  {
    id: 'polypup-ball-wmatic-ball-eol',
    name: 'BALL-MATIC QLP',
    token: 'BALL-MATIC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0xe11eb426206960e9533828031cDFED0B4B61749C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupBallWMATIC-BALL',
    earnedTokenAddress: '0xC10ac3fe9b11ff6763F9f1dE3c1C00267974553B',
    earnContractAddress: '0xC10ac3fe9b11ff6763F9f1dE3c1C00267974553B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-ball-wmatic-ball',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BALL', 'MATIC'],
    withdrawalFee: '0.0%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x883aBe4168705d2e5dA925d28538B7a6AA9d8419',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0x883aBe4168705d2e5dA925d28538B7a6AA9d8419',
    createdAt: 1629258699,
  },
  {
    id: 'dinoswap-dfyn-route-eth-eol',
    name: 'ROUTE-ETH LP',
    token: 'ROUTE-ETH LP',
    tokenDescription: 'DFyn (DinoSwap)',
    tokenAddress: '0xeBC4f9B1cE66258AC3A48578FFEEba1330dDB68B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDinoSwapDfynROUTE-ETH',
    earnedTokenAddress: '0x655AF65e1f26ccB1dD18EF1DA93E813911818c5e',
    earnContractAddress: '0x655AF65e1f26ccB1dD18EF1DA93E813911818c5e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dinoswap-dfyn-route-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['ROUTE', 'ETH'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xeBC4f9B1cE66258AC3A48578FFEEba1330dDB68B',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xeBC4f9B1cE66258AC3A48578FFEEba1330dDB68B',
    createdAt: 1629211050,
  },
  {
    id: 'quick-usdc-quick',
    name: 'QUICK-USDC LP',
    token: 'QUICK-USDC LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0x1F1E4c845183EF6d50E9609F16f6f9cAE43BC9Cb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-USDC',
    earnedTokenAddress: '0xeD7148949c2dC8AA59A5aD8658eF347Bd7387fA8',
    earnContractAddress: '0xeD7148949c2dC8AA59A5aD8658eF347Bd7387fA8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
    createdAt: 1629158545,
  },
  {
    id: 'quick-wmatic-avax',
    name: 'AVAX-MATIC LP',
    token: 'AVAX-MATIC LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0xEb477AE74774b697B5D515eF8ca09e24FEe413b5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickAVAX-WMATIC',
    earnedTokenAddress: '0x764B2aAcfDE7e33888566a6d44005Dc982F02031',
    earnContractAddress: '0x764B2aAcfDE7e33888566a6d44005Dc982F02031',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-wmatic-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['AVAX', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b',
    createdAt: 1629159263,
  },
  {
    id: 'pearzap-pear-usdc',
    name: 'PEAR-USDC LP',
    token: 'PEAR-USDC LP',
    tokenDescription: 'WaultFinance (PearZap)',
    tokenAddress: '0xa1d88Ce0d030B51342a05f9Dcc10AC4214592DAc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPearZapPEAR-USDC',
    earnedTokenAddress: '0x3e9C032052e774B85CbF5f1D6382be06144e1Bac',
    earnContractAddress: '0x3e9C032052e774B85CbF5f1D6382be06144e1Bac',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pearzap-pear-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['PEAR', 'USDC'],
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xc8bcb58caEf1bE972C0B638B1dD8B0748Fdc8A44',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0xc8bcb58caEf1bE972C0B638B1dD8B0748Fdc8A44',
    createdAt: 1628607022,
  },
  {
    id: 'pearzap-pear-matic',
    name: 'PEAR-MATIC LP',
    token: 'PEAR-MATIC LP',
    tokenDescription: 'WaultFinance (PearZap)',
    tokenAddress: '0x51BEffd36EB8c81a9b440B7ee51f98ba9eFda707',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPearZapPEAR-MATIC',
    earnedTokenAddress: '0xA5cd75EdC2f64580470bB6Fb2f9Ee00C66747c65',
    earnContractAddress: '0xA5cd75EdC2f64580470bB6Fb2f9Ee00C66747c65',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pearzap-pear-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['PEAR', 'MATIC'],
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0xc8bcb58caEf1bE972C0B638B1dD8B0748Fdc8A44',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0xc8bcb58caEf1bE972C0B638B1dD8B0748Fdc8A44',
    createdAt: 1628607888,
  },
  {
    id: 'polygonfarm-spade-matic-eol',
    name: 'SPADE-MATIC LP',
    token: 'SPADE-MATIC LP',
    tokenDescription: 'SushiSwap (PolygonFarm)',
    tokenAddress: '0xCcCEc4A90b3435065f5e1feC6346be9Da1B7B5eD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyFarmSPADE-MATIC',
    earnedTokenAddress: '0x07628d57b68E3ec227496740820A6fDa1b0DD0d8',
    earnContractAddress: '0x07628d57b68E3ec227496740820A6fDa1b0DD0d8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polygonfarm-spade-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    retireReason: 'rewards',
    assets: ['SPADE', 'MATIC'],
    addLiquidityUrl: 'https://app.sushi.com/add/ETH/0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
    createdAt: 1628592682,
  },
  {
    id: 'polygonfarm-spade-usdc-eol',
    name: 'SPADE-USDC LP',
    token: 'SPADE-USDC LP',
    tokenDescription: 'SushiSwap (PolygonFarm)',
    tokenAddress: '0x6Fd8aAe9f85A7Db14c45453daAB81aa3085E4bA3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyFarmSPADE-USDC',
    earnedTokenAddress: '0xEEc9a4Ecd1C0caB2Eb20081A10b8b0A07336dA14',
    earnContractAddress: '0xEEc9a4Ecd1C0caB2Eb20081A10b8b0A07336dA14',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polygonfarm-spade-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['SPADE', 'USDC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
    createdAt: 1628592884,
  },
  {
    id: 'cometh-eth-wbtc',
    name: 'ETH-WBTC LP',
    token: 'ETH-WBTC LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x4282C6b1c21CD1A0C363a70e4292f8Bf4D7c898c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethETH-WBTC',
    earnedTokenAddress: '0x55eebAd2b295d5229cf853368c9307e48B9CD955',
    earnContractAddress: '0x55eebAd2b295d5229cf853368c9307e48B9CD955',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-eth-wbtc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['ETH', 'WBTC'],
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    createdAt: 1627720202,
  },
  {
    id: 'cometh-eth-usdc',
    name: 'ETH-USDC LP',
    token: 'ETH-USDC LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x1Edb2D8f791D2a51D56979bf3A25673D6E783232',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethETH-USDC',
    earnedTokenAddress: '0x47036A1ecC5D02e122Ae6604973ee1529E76e022',
    earnContractAddress: '0x47036A1ecC5D02e122Ae6604973ee1529E76e022',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-eth-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['ETH', 'USDC'],
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    createdAt: 1628517667,
  },
  {
    id: 'quick-sol-matic-eol',
    name: 'SOL-MATIC LP',
    token: 'SOL-MATIC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x898386DD8756779a4ba4f1462891B92dd76b78eF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickSOL-MATIC',
    earnedTokenAddress: '0x8802fbcb669c7BbcC3989455B3FdBF9235176bD4',
    earnContractAddress: '0x8802fbcb669c7BbcC3989455B3FdBF9235176bD4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-sol-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['SOL', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4',
    createdAt: 1628164489,
  },
  {
    id: 'quick-uni-eth-eol',
    name: 'UNI-ETH LP',
    token: 'UNI-ETH LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xF7135272a5584Eb116f5a77425118a8B4A2ddfDb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUNI-ETH',
    earnedTokenAddress: '0x80fcA0946fb75724f5d84Dd49edC694062E3111C',
    earnContractAddress: '0x80fcA0946fb75724f5d84Dd49edC694062E3111C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-uni-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['UNI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xb33EaAd8d922B1083446DC23f610c2567fB5180f/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    createdAt: 1627994474,
  },
  {
    id: 'quick-guard-usdc-eol',
    name: 'GUARD-USDC LP',
    token: 'GUARD-USDC LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xD2EeeEdfcAF1457F7BC9cbA28d5316F73BB83b49',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickGUARD-USDC',
    earnedTokenAddress: '0x1081062Aa050AFDBC3D669A24856a6fD23C536Fc',
    earnContractAddress: '0x1081062Aa050AFDBC3D669A24856a6fD23C536Fc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-guard-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['GUARD', 'USDC'],
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
    createdAt: 1628007315,
  },
  {
    id: 'dinoswap-dino-eth-eol',
    name: 'DINO-ETH LP',
    token: 'DINO-ETH LP',
    tokenDescription: 'Quickswap (DinoSwap)',
    tokenAddress: '0x9f03309A588e33A239Bf49ed8D68b2D45C7A1F11',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDinoSwapDINO-ETH',
    earnedTokenAddress: '0xE87151D8dd8695e3e69e8B2f0eB8cf79BD2227d1',
    earnContractAddress: '0xE87151D8dd8695e3e69e8B2f0eB8cf79BD2227d1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dinoswap-dino-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['DINO', 'ETH'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xAa9654BECca45B5BDFA5ac646c939C62b527D394',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xAa9654BECca45B5BDFA5ac646c939C62b527D394',
    createdAt: 1627984193,
  },
  {
    id: 'dinoswap-dfyn-ust-usdt-eol',
    name: 'UST-USDT LP',
    token: 'UST-USDT LP',
    tokenDescription: 'DFyn (DinoSwap)',
    tokenAddress: '0x39BEd7f1C412ab64443196A6fEcb2ac20C707224',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDinoSwapDfynUST-USDT',
    earnedTokenAddress: '0xa2e51538fce9d2D8082988921be4C0afC3Fe089a',
    earnContractAddress: '0xa2e51538fce9d2D8082988921be4C0afC3Fe089a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dinoswap-dfyn-ust-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['UST', 'USDT'],
    withdrawalFee: '0.01%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x692597b009d13C4049a947CAB2239b7d6517875F/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x692597b009d13C4049a947CAB2239b7d6517875F&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    createdAt: 1627747757,
  },
  {
    id: 'dinoswap-dfyn-eth-dfyn-eol',
    name: 'DFYN-ETH LP',
    token: 'DFYN-ETH LP',
    tokenDescription: 'DFyn (DinoSwap)',
    tokenAddress: '0x6fA867BBFDd025780a8CFE988475220AfF51FB8b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDinoSwapDfynETH-DFYN',
    earnedTokenAddress: '0x05064BF1Bc9f6778c69c3f68E17Ba8d9427594D8',
    earnContractAddress: '0x05064BF1Bc9f6778c69c3f68E17Ba8d9427594D8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dinoswap-dfyn-eth-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['DFYN', 'ETH'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    createdAt: 1627747533,
  },
  {
    id: 'quick-quick-yamp-eol',
    name: 'YAMP-QUICK LP',
    token: 'YAMP-QUICK LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x66fF795535CF162D29f6B15ed546a3e148Eff0Fb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-YAMP',
    earnedTokenAddress: '0x0BeCc1ff5f13b00A77cA359d0a15a0989766380E',
    earnContractAddress: '0x0BeCc1ff5f13b00A77cA359d0a15a0989766380E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-yamp',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['QUICK', 'YAMP'],
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0x87f654c4b347230C60CAD8d7ea9cF0D7238bcc79',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x87f654c4b347230C60CAD8d7ea9cF0D7238bcc79',
    createdAt: 1627653529,
  },
  {
    id: 'quick-quick-rendgb-eol',
    name: 'renDGB-QUICK LP',
    token: 'renDGB-QUICK LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5Cb85aa163B1B443F88A1f9124153E70F6586400',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-renDGB',
    earnedTokenAddress: '0x6d486dD56e2191E87c275722FBADA1aADa2F2Aff',
    earnContractAddress: '0x6d486dD56e2191E87c275722FBADA1aADa2F2Aff',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-rendgb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['QUICK', 'renDGB'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0x2628568509E87c4429fBb5c664Ed11391BE1BD29',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x2628568509E87c4429fBb5c664Ed11391BE1BD29',
    createdAt: 1627656527,
  },
  {
    id: 'quick-quick-polydoge-eol',
    name: 'polyDOGE-QUICK LP',
    token: 'polyDOGE-QUICK LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0xbedEE6a7c572aa855a0c84d2f504311d482862F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-PolyDoge',
    earnedTokenAddress: '0x4A2De8466873fCb21D7bDE5A754Baaf244729c72',
    earnContractAddress: '0x4A2De8466873fCb21D7bDE5A754Baaf244729c72',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-polydoge',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['PolyDoge', 'QUICK'],
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
    createdAt: 1627489669,
  },
  {
    id: 'farmhero-quick-usdc-honor-eol',
    name: 'HONOR-USDC QLP',
    token: 'HONOR-USDC QLP',
    tokenDescription: 'FarmHero (Quickswap)',
    tokenAddress: '0x46489f825f11d7473D20279699b108aCAA246e73',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFarmHeroQuickUSDC-HONOR',
    earnedTokenAddress: '0xD8f5095Fd7E27D4aD12008d5eb09105bFFd1572c',
    earnContractAddress: '0xD8f5095Fd7E27D4aD12008d5eb09105bFFd1572c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'farmhero-quick-usdc-honor',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['HONOR', 'USDC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xb82A20B4522680951F11c94c54B8800c1C237693',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xb82A20B4522680951F11c94c54B8800c1C237693',
    createdAt: 1627447004,
    retiredReason: 'tvl',
  },
  {
    id: 'farmhero-wault-usdc-honor-eol',
    name: 'HONOR-USDC WLP',
    token: 'HONOR-USDC WLP',
    tokenDescription: 'FarmHero (Wault)',
    tokenAddress: '0x4Bd5dF4300b7c5309cB1A0143e4A6d0184B878e1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFarmHeroWaultUSDC-HONOR',
    earnedTokenAddress: '0x0cFc878111e72e800ecA9E0B2a6358bcc15EB9FE',
    earnContractAddress: '0x0cFc878111e72e800ecA9E0B2a6358bcc15EB9FE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'farmhero-wault-usdc-honor',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['HONOR', 'USDC'],
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xb82A20B4522680951F11c94c54B8800c1C237693',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xb82A20B4522680951F11c94c54B8800c1C237693',
    createdAt: 1627447222,
    retiredReason: 'tvl',
  },
  {
    id: 'dfyn-matic-dfyn-eol',
    name: 'WMATIC-DFYN LP',
    token: 'WMATIC-DFYN LP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x740d668883b0fAEf5eeF7E84BE28C7152D6f609D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFynMATIC-DFYN',
    earnedTokenAddress: '0x4a759386cacb60A714275F97AbA9b555d91001c9',
    earnContractAddress: '0x4a759386cacb60A714275F97AbA9b555d91001c9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-matic-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['WMATIC_DFYN', 'DFYN', 'WMATIC'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/ETH/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=ETH&outputCurrency=0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    createdAt: 1627604368,
  },
  {
    id: 'dfyn-luna-dfyn-eol',
    name: 'LUNA-DFYN LP',
    token: 'LUNA-DFYN LP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x7E2cE68E76E94cB4C35b3Ab66d03e66efd7641a7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFynLUNA-DFYN',
    earnedTokenAddress: '0x653B205f0ceB4dF38DBFBfEAcaacC2003C553883',
    earnContractAddress: '0x653B205f0ceB4dF38DBFBfEAcaacC2003C553883',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-luna-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['LUNA', 'DFYN'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x24834bbec7e39ef42f4a75eaf8e5b6486d3f0e57/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x24834bbec7e39ef42f4a75eaf8e5b6486d3f0e57&outputCurrency=0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    createdAt: 1627605208,
  },
  {
    id: 'dfyn-uni-dfyn-eol',
    name: 'UNI-DFYN LP',
    token: 'UNI-DFYN LP',
    tokenDescription: 'DFyn',
    tokenAddress: '0xE8c9bB82443c55d1c0Bdd12f7e9cbEc5974eCf43',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFynUNI-DFYN',
    earnedTokenAddress: '0x16B6BFE58deDF2934C6E9BE1D0cBEcaafc2C686d',
    earnContractAddress: '0x16B6BFE58deDF2934C6E9BE1D0cBEcaafc2C686d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-uni-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['UNI', 'DFYN'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0xb33EaAd8d922B1083446DC23f610c2567fB5180f/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0xb33EaAd8d922B1083446DC23f610c2567fB5180f&outputCurrency=0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    createdAt: 1627605778,
  },
  {
    id: 'dfyn-crv-dfyn-eol',
    name: 'CRV-DFYN LP',
    token: 'CRV-DFYN LP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x4Ea3e2cfC39FA51Df85ebcfa366d7f0eED448a1C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFynCRV-DFYN',
    earnedTokenAddress: '0x259676497409B89Da5655468551685dFbec1EA57',
    earnContractAddress: '0x259676497409B89Da5655468551685dFbec1EA57',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-crv-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['CRV', 'DFYN'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x172370d5Cd63279eFa6d502DAB29171933a610AF/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x172370d5Cd63279eFa6d502DAB29171933a610AF&outputCurrency=0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    createdAt: 1627605878,
  },
  {
    id: 'polyyeld-xyeld-quick-wmatic-xyeld-eol',
    name: 'xYELD-MATIC QLP',
    token: 'xYELD-MATIC QLP',
    tokenDescription: 'Polyyeld (L2)',
    tokenAddress: '0x6Ea785d8edeA0a99c7040839d8fA0498c3c9a319',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldL2QuickWMATIC-xYELD',
    earnedTokenAddress: '0xa450f23570868A33bc66bF60f2268E85575bbDC9',
    earnContractAddress: '0xa450f23570868A33bc66bF60f2268E85575bbDC9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-xyeld-quick-wmatic-xyeld',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['xYELD', 'MATIC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x90AC3fa9fCD997B168f218041de26eB01399Bb55',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0x90AC3fa9fCD997B168f218041de26eB01399Bb55',
    createdAt: 1626553687,
  },
  {
    id: 'polycracker-lith-eol',
    logo: 'single-assets/LITHIUM.svg',
    name: 'LITHIUM',
    token: 'LITHIUM',
    tokenDescription: 'PolyWantsACracker',
    tokenAddress: '0xfE1a200637464FBC9B60Bc7AeCb9b86c0E1d486E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooPolyCrackerLITH',
    earnedTokenAddress: '0xE08d7B24E2D22B242dEA3DE85032261810F35B09',
    earnContractAddress: '0xE08d7B24E2D22B242dEA3DE85032261810F35B09',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'LITHIUM',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['LITHIUM'],
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xfE1a200637464FBC9B60Bc7AeCb9b86c0E1d486E',
    createdAt: 1627398246,
  },
  {
    id: 'polycracker-lith-usdc-eol',
    name: 'LITHIUM-USDC LP',
    token: 'LITHIUM-USDC LP',
    tokenDescription: 'Quickswap (PolyWantsACracker)',
    tokenAddress: '0x1b9c6d8b372C410426CF803fd20a815aA7B454c2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooPolyCrackerLITH-USDC',
    earnedTokenAddress: '0x51A61e614E18B9c50f2420e77Bb071B5fF6D7aB7',
    earnContractAddress: '0x51A61e614E18B9c50f2420e77Bb071B5fF6D7aB7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polycracker-lith-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['LITHIUM', 'USDC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xfE1a200637464FBC9B60Bc7AeCb9b86c0E1d486E',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xfE1a200637464FBC9B60Bc7AeCb9b86c0E1d486E',
    createdAt: 1627397974,
  },
  {
    id: 'dinoswap-xmark-usdc-eol',
    name: 'xMARK-USDC LP',
    token: 'xMARK-USDC LP',
    tokenDescription: 'SushiSwap (DinoSwap)',
    tokenAddress: '0x97A95deb56d689802F02f50c25EBCda5d0A49591',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDinoSwapXMARK-USDC',
    earnedTokenAddress: '0xFa08E2EFE3aa5a8a0142f26add94354f9FdFe1DE',
    earnContractAddress: '0xFa08E2EFE3aa5a8a0142f26add94354f9FdFe1DE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dinoswap-xmark-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['xMARK', 'USDC'],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xf153eff70dc0bf3b085134928daeea248d9b30d0',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xf153eff70dc0bf3b085134928daeea248d9b30d0',
    createdAt: 1627204805,
  },
  {
    id: 'dinoswap-dino-usdc-eol',
    name: 'DINO-USDC LP',
    token: 'DINO-USDC LP',
    tokenDescription: 'SushiSwap (DinoSwap)',
    tokenAddress: '0x3324af8417844e70b81555A6D1568d78f4D4Bf1f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDinoSwapDINO-USDC',
    earnedTokenAddress: '0x6a195660472a51f267230c3324F86055D2A8B1E2',
    earnContractAddress: '0x6a195660472a51f267230c3324F86055D2A8B1E2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dinoswap-dino-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['DINO', 'USDC'],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xAa9654BECca45B5BDFA5ac646c939C62b527D394',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xAa9654BECca45B5BDFA5ac646c939C62b527D394',
    createdAt: 1626613413,
  },
  {
    id: 'dinoswap-usdc-mimatic-eol',
    name: 'MAI-USDC LP',
    token: 'MAI-USDC QLP',
    tokenDescription: 'Quickswap (DinoSwap)',
    tokenAddress: '0x160532D2536175d65C03B97b0630A9802c274daD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDinoSwapUSDC-MIMATIC',
    earnedTokenAddress: '0x92dEB173538F4f4dCf51B8A878b6C816C034BcdF',
    earnContractAddress: '0x92dEB173538F4f4dCf51B8A878b6C816C034BcdF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dinoswap-usdc-mimatic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['MAI', 'USDC'],
    withdrawalFee: '0.01%',
    retireReason: 'rewards',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    createdAt: 1626609638,
  },
  {
    id: 'dinoswap-usdc-usdt-eol',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT QLP',
    tokenDescription: 'Quickswap (DinoSwap)',
    tokenAddress: '0x2cF7252e74036d1Da831d11089D326296e64a728',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDinoSwapUSDC-USDT',
    earnedTokenAddress: '0x4cE9E554a62672Fc9Ceecb26ce48B742b75D4658',
    earnContractAddress: '0x4cE9E554a62672Fc9Ceecb26ce48B742b75D4658',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dinoswap-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['USDC', 'USDT'],
    withdrawalFee: '0.01%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    createdAt: 1626611305,
  },
  {
    id: 'jetswap-poly-usdc-usdt',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x20BF018FDDBa3b352f3d913FE1c81b846fE0F490',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapUSDC-USDT',
    earnedTokenAddress: '0x93c9f29CF2496e73f3d8b07055e2359267207147',
    earnContractAddress: '0x93c9f29CF2496e73f3d8b07055e2359267207147',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-poly-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['USDC', 'USDT'],
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://polygon-exchange.jetswap.finance/#/add/0xc2132D05D31c914a87C6611C10748AEb04B58e8F/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    createdAt: 1625847401,
  },
  {
    id: 'mai-usdc-mimatic',
    name: 'MAI-USDC LP',
    token: 'MAI-USDC QLP',
    tokenDescription: 'QuickSwap (Mai)',
    tokenAddress: '0x160532D2536175d65C03B97b0630A9802c274daD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMaiUSDC-miMATIC',
    earnedTokenAddress: '0xebe0c8d842AA5A57D7BEf8e524dEabA676F91cD1',
    earnContractAddress: '0xebe0c8d842AA5A57D7BEf8e524dEabA676F91cD1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mai-usdc-mimatic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mai',
    assets: ['MAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    depositFee: '0.5%',
    withdrawalFee: '0.0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    createdAt: 1624076992,
  },
  {
    id: 'polypup-usdc-bone-eol',
    name: 'BONE-USDC QLP',
    token: 'BONE-USDC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0x2CC05c660f35E8692CA99dB95922CB744d44ef20',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupUSDC-BONE',
    earnedTokenAddress: '0x8Ce906F6f383c31b09B1D2A5f2c9f480b87ceb58',
    earnContractAddress: '0x8Ce906F6f383c31b09B1D2A5f2c9f480b87ceb58',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-usdc-bone',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BONE', 'USDC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    createdAt: 1625098451,
  },
  {
    id: 'polyyeld-xyeld-xyeld-eol',
    logo: 'single-assets/xYELD.svg',
    name: 'xYELD',
    token: 'xYELD',
    tokenDescription: 'Polyyeld (L2)',
    tokenAddress: '0x90AC3fa9fCD997B168f218041de26eB01399Bb55',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldL2xYELD',
    earnedTokenAddress: '0x2Fa3A4671b977a8f0c16334e4CF16EA91F22db75',
    earnContractAddress: '0x2Fa3A4671b977a8f0c16334e4CF16EA91F22db75',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'xYELD',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['xYELD'],
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x90AC3fa9fCD997B168f218041de26eB01399Bb55',
    createdAt: 1626552470,
  },
  {
    id: 'polyyeld-xyeld-yeld-eol',
    logo: 'single-assets/YELD.png',
    name: 'YELD',
    token: 'YELD',
    tokenDescription: 'Polyyeld (L2)',
    tokenAddress: '0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldL2YELD',
    earnedTokenAddress: '0xa6D7AbC6D051314eD22a98ED74E41278516d3bbd',
    earnContractAddress: '0xa6D7AbC6D051314eD22a98ED74E41278516d3bbd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'YELD',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['YELD'],
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891',
    createdAt: 1626551580,
  },
  {
    id: 'polyyeld-xyeld-quick-wmatic-yeld-eol',
    name: 'YELD-MATIC QLP',
    token: 'YELD-MATIC QLP',
    tokenDescription: 'Polyyeld (L2)',
    tokenAddress: '0xef71F798c15f8C9732488e109BB511144088bECe',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldL2QuickWMATIC-YELD',
    earnedTokenAddress: '0xB215bFd4821C71914F92093cae527FD355CE268F',
    earnContractAddress: '0xB215bFd4821C71914F92093cae527FD355CE268F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-xyeld-quick-wmatic-yeld',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['YELD', 'MATIC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891',
    createdAt: 1626553815,
  },
  {
    id: 'quick-nexo-eth-eol',
    name: 'NEXO-ETH LP',
    token: 'NEXO-ETH LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0x10062ec62C0bE26cC9e2f50a1CF784a89ded075F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickNEXO-ETH',
    earnedTokenAddress: '0xba1548b3f673950094dc00eDc1eB71683f371696',
    earnContractAddress: '0xba1548b3f673950094dc00eDc1eB71683f371696',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-nexo-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['NEXO', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E',
    createdAt: 1626349862,
  },
  {
    id: 'telxchange-quick-quick-tel-eol',
    name: 'TEL-QUICK QLP',
    token: 'TEL-QUICK QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0xE88e24F49338f974B528AcE10350Ac4576c5c8A1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickQUICK-TEL',
    earnedTokenAddress: '0xda3E3ee01Ee7284e3D0d900Ca3fb3D09484E1ce0',
    earnContractAddress: '0xda3E3ee01Ee7284e3D0d900Ca3fb3D09484E1ce0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-quick-tel',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TEL', 'QUICK'],
    retireReason: 'rewards',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    createdAt: 1626289895,
  },
  {
    id: 'telxchange-quick-wmatic-tel',
    name: 'TEL-MATIC QLP',
    token: 'TEL-MATIC QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0x9b5c71936670e9f1F36e63F03384De7e06E60d2a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickWMATIC-TEL',
    earnedTokenAddress: '0x2AaB02eDE64d4A686EA1d1A70B688E46cce50fFd',
    earnContractAddress: '0x2AaB02eDE64d4A686EA1d1A70B688E46cce50fFd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-wmatic-tel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['TEL', 'MATIC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    createdAt: 1626322235,
  },
  {
    id: 'telxchange-quick-eth-tel-eol',
    name: 'TEL-ETH QLP',
    token: 'TEL-ETH QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0xFc2fC983a411C4B1E238f7Eb949308CF0218C750',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickETH-TEL',
    earnedTokenAddress: '0xDC52e53f9E8b2cf42b557A6b3cE36e79De39ACd6',
    earnContractAddress: '0xDC52e53f9E8b2cf42b557A6b3cE36e79De39ACd6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-eth-tel',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TEL', 'ETH'],
    retireReason: 'rewards',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    createdAt: 1626306817,
  },
  {
    id: 'telxchange-quick-aave-tel',
    name: 'TEL-AAVE QLP',
    token: 'TEL-AAVE QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0x4917bC6b8E705Ad462ef525937E7eB7C6c87C356',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickAAVE-TEL',
    earnedTokenAddress: '0xBa58D7131Ca50DdFB458f37ea0De6fdcebFe836B',
    earnContractAddress: '0xBa58D7131Ca50DdFB458f37ea0De6fdcebFe836B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-aave-tel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['TEL', 'AAVE'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    createdAt: 1626327159,
  },
  {
    id: 'telxchange-quick-wbtc-tel',
    name: 'TEL-BTC QLP',
    token: 'TEL-BTC QLP',
    tokenDescription: 'Telxchange',
    tokenAddress: '0xaDdc9C73f3CBaD4E647eAFf691715898825Ac20c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTelxchangeQuickWBTC-TEL',
    earnedTokenAddress: '0xb74792F6a2cF81E3f704930621cb4832ffFcC840',
    earnContractAddress: '0xb74792F6a2cF81E3f704930621cb4832ffFcC840',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'telxchange-quick-wbtc-tel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['TEL', 'WBTC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&outputCurrency=0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
    createdAt: 1626327313,
  },
  {
    id: 'curve-poly-atricrypto',
    logo: 'uncategorized/ATRICRYPTO.png',
    name: 'aTriCrypto',
    token: 'crvUSDBTCETH',
    tokenDescription: 'Curve',
    tokenAddress: '0x8096ac61db23291252574D49f036f0f9ed8ab390',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveATriCrypto',
    earnedTokenAddress: '0x3dab1aCB811dc4C8b2FdC77812552f4d4Efd0A8c',
    earnContractAddress: '0x3dab1aCB811dc4C8b2FdC77812552f4d4Efd0A8c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-poly-atricrypto',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Curve',
    assets: ['DAI', 'USDC', 'USDT', 'WBTC', 'ETH'],
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://polygon.curve.fi/atricrypto/deposit',
    createdAt: 1625140527,
  },
  {
    id: 'dfyn-ice-dfyn-eol',
    name: 'ICE-DFYN LP',
    token: 'ICE-DFYN LP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x9bb608dc0F9308B9beCA2F7c80865454d02E74cA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDfynICE-DFYN',
    earnedTokenAddress: '0xB198A916123394f2d9c31D4645468566e87080d5',
    earnContractAddress: '0xB198A916123394f2d9c31D4645468566e87080d5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-ice-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['ironICE', 'DFYN'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?outputCurrency=0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef',
    createdAt: 1626270600,
  },
  {
    id: 'ironswap-ice-eth-eol',
    name: 'ICE-ETH LP',
    token: 'ICE-ETH LP',
    tokenDescription: 'IronSwap (Firebird)',
    tokenAddress: '0xf1EE78544a1118F2efb87f7EaCd9f1E6e80e1ea5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSwapICE-ETH',
    earnedTokenAddress: '0x90D0F2050e62a6d4AF088dD09d802D9A18253c40',
    earnContractAddress: '0x90D0F2050e62a6d4AF088dD09d802D9A18253c40',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ironswap-ice-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'IronFinance',
    assets: ['ironICE', 'ETH'],
    addLiquidityUrl: 'https://app.firebird.finance/add/0xf1ee78544a1118f2efb87f7eacd9f1e6e80e1ea5',
    buyTokenUrl:
      'https://app.firebird.finance/swap?outputCurrency=0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef',
    createdAt: 1626155225,
    retiredReason: 'tvl',
  },
  {
    id: 'ironswap-ice-usdc-eol',
    name: 'ICE-USDC LP',
    token: 'ICE-USDC LP',
    tokenDescription: 'IronSwap (DFYN)',
    tokenAddress: '0x34832D9AC4127a232C1919d840f7aaE0fcb7315B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSwapICE-USDC',
    earnedTokenAddress: '0x0564889d4Ff0dA0371Ec8a898AC39ABC99065BfA',
    earnContractAddress: '0x0564889d4Ff0dA0371Ec8a898AC39ABC99065BfA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ironswap-ice-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'IronFinance',
    assets: ['ironICE', 'USDC'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?outputCurrency=0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef',
    createdAt: 1626164436,
    retiredReason: 'tvl',
  },
  {
    id: 'ironswap-3usd',
    logo: 'uncategorized/IRON-3USD.png',
    name: 'USDC/USDT/DAI',
    token: 'IS3USD',
    tokenDescription: 'IronSwap',
    tokenAddress: '0xb4d09ff3dA7f9e9A2BA029cb0A81A989fd7B8f17',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSwap3USD',
    earnedTokenAddress: '0x0C4705EBd2BF69660b72f9E9B684714c9e341942',
    earnContractAddress: '0x0C4705EBd2BF69660b72f9E9B684714c9e341942',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ironswap-3usd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    assets: ['USDC', 'USDT', 'DAI'],
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://app.iron.finance/swap/pools/is3usd/deposit',
    createdAt: 1626100387,
  },
  {
    id: 'wexpoly-wbtc-wex-eol',
    logo: 'polygon/WEXPOLY-BTC.png',
    name: 'WEXpoly-WBTC LP',
    token: 'WEXpoly-WBTC WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xaE183DB956FAf760Aa29bFcfDa4BDDdB02fbdd0E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWBTC-WEX ',
    earnedTokenAddress: '0xA1B2Ef1b6D5823D832DDa2AE2B489793832Fd082',
    earnContractAddress: '0xA1B2Ef1b6D5823D832DDa2AE2B489793832Fd082',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wbtc-wex',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['WEXpoly', 'WBTC'],
    risks: [
      'IL_HIGH',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?inputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&outputCurrency=0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    createdAt: 1625995689,
  },
  {
    id: 'dfyn-bifi-dfyn-eol',
    name: 'BIFI-DFYN LP',
    token: 'BIFI-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x759738096589b9C39dA78A603565635a3Cee6013',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDfynBIFI-DFYN',
    earnedTokenAddress: '0x74b00bbb693de4dC07d8aB79Ac935b323e2A136a',
    earnContractAddress: '0x74b00bbb693de4dC07d8aB79Ac935b323e2A136a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-bifi-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BIFI', 'DFYN'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0xfbdd194376de19a88118e84e279b977f165d01b8/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    createdAt: 1625919162,
  },
  {
    id: 'jetswap-poly-pwings-eol',
    logo: 'single-assets/pWINGS.png',
    name: 'pWINGS',
    token: 'pWINGS',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapPWINGS',
    earnedTokenAddress: '0xa55e8326a9AC7B0bFcA5E6Fc9Cf1141E2dc11f67',
    earnContractAddress: '0xa55e8326a9AC7B0bFcA5E6Fc9Cf1141E2dc11f67',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'pWINGS',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetSwap',
    assets: ['pWINGS'],
    buyTokenUrl:
      'https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    createdAt: 1625919720,
    retiredReason: 'tvl',
  },
  {
    id: 'jetswap-poly-pwings-usdc-eol',
    name: 'pWINGS-USDC LP',
    token: 'pWINGS-USDC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xaf623E96d38191038C48990Df298e07Fb77b56c3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapPWINGS-USDC',
    earnedTokenAddress: '0xaA66e6EA1cAa7B86a8119B1420F6EdeDE3bb58f1',
    earnContractAddress: '0xaA66e6EA1cAa7B86a8119B1420F6EdeDE3bb58f1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-poly-pwings-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetSwap',
    assets: ['pWINGS', 'USDC'],
    addLiquidityUrl:
      'https://polygon-exchange.jetswap.finance/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    buyTokenUrl:
      'https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    createdAt: 1625844034,
  },
  {
    id: 'jetswap-poly-pwings-matic-eol',
    name: 'pWINGS-MATIC LP',
    token: 'pWINGS-MATIC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xA0A6e9A5185d5737CF6F7920CB417EA2F07F03B3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapPWINGS-MATIC',
    earnedTokenAddress: '0x97fe99a9bc7db115EC89Bc2627eC7f0518409121',
    earnContractAddress: '0x97fe99a9bc7db115EC89Bc2627eC7f0518409121',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-poly-pwings-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetSwap',
    assets: ['pWINGS', 'MATIC'],
    addLiquidityUrl:
      'https://polygon-exchange.jetswap.finance/#/add/MATIC/0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    buyTokenUrl:
      'https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25',
    createdAt: 1625845966,
    retiredReason: 'tvl',
  },
  {
    id: 'mai-qi-mimatic-eol',
    name: 'QI-MAI LP',
    token: 'QI-MAI QLP',
    tokenDescription: 'QuickSwap (Mai)',
    tokenAddress: '0x7AfcF11F3e2f01e71B7Cc6b8B5e707E42e6Ea397',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMaiQI-miMATIC',
    earnedTokenAddress: '0xa2Dd60Fb8Bbf21e60E58AF97Cfc9dB9A34397848',
    earnContractAddress: '0xa2Dd60Fb8Bbf21e60E58AF97Cfc9dB9A34397848',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mai-qi-mimatic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Mai',
    assets: ['QI', 'MAI'],
    depositFee: '0.5%',
    withdrawalFee: '0.0%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x580A84C73811E1839F75d86d75d88cCa0c241fF4/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xa3fa99a148fa48d14ed51d610c367c61876997f1&outputCurrency=0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
    createdAt: 1624074452,
  },
  {
    id: 'quick-qi-quick-eol',
    name: 'QI-QUICK LP',
    token: 'QI-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x25d56E2416f20De1Efb1F18fd06dD12eFeC3D3D0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQI-QUICK',
    earnedTokenAddress: '0x1d7A7182043F521ab57Fe07a8420Fee9843E365e',
    earnContractAddress: '0x1d7A7182043F521ab57Fe07a8420Fee9843E365e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-qi-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['QI', 'QUICK'],
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x580A84C73811E1839F75d86d75d88cCa0c241fF4/0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13&outputCurrency=0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
    createdAt: 1624073954,
  },
  {
    id: 'quick-usdc-mimatic-eol',
    name: 'MAI-USDC LP',
    token: 'MAI-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x160532D2536175d65C03B97b0630A9802c274daD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-miMATIC',
    earnedTokenAddress: '0xf62c521c4fb3Fbc20EaB50E1871A90A53632f22e',
    earnContractAddress: '0xf62c521c4fb3Fbc20EaB50E1871A90A53632f22e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-mimatic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['MAI', 'USDC'],
    withdrawalFee: '0.01%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    createdAt: 1623631997,
  },
  {
    id: 'quick-polybunny-eth-eol',
    name: 'polyBUNNY-ETH LP',
    token: 'polyBUNNY-ETH LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0x62052b489Cb5bC72a9DC8EEAE4B24FD50639921a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickpolyBUNNY-ETH',
    earnedTokenAddress: '0x6C4593D4f91c790404cCEe5007E929d2b7bF45bD',
    earnContractAddress: '0x6C4593D4f91c790404cCEe5007E929d2b7bF45bD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-polybunny-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['polyBUNNY', 'ETH'],
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
    createdAt: 1625698210,
  },
  {
    id: 'quick-polybunny-quick-eol',
    name: 'polyBUNNY-QUICK LP',
    token: 'polyBUNNY-QUICK LP',
    tokenDescription: 'Quickswap',
    tokenAddress: '0x976b7B7fE4293111CaCd946C422a64f24A223564',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickpolyBUNNY-QUICK',
    earnedTokenAddress: '0x0e229c6381f00F4be47a9529CBDde18016dD9369',
    earnContractAddress: '0x0e229c6381f00F4be47a9529CBDde18016dD9369',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-polybunny-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['polyBUNNY', 'QUICK'],
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
    createdAt: 1625698046,
  },
  {
    id: 'boneswap-quick-wmatic-bone-eol',
    name: 'BONE-MATIC LP',
    token: 'BONE-MATIC LP',
    tokenDescription: 'Quickswap (BoneSwap)',
    tokenAddress: '0x2d10b334646F6e61F2556c0f7eda2fF3D82d001e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoneSwapQuickBONE-WMATIC',
    earnedTokenAddress: '0xe9CAf4DfeaB51114a619C8104C38a309ccA236E1',
    earnContractAddress: '0xe9CAf4DfeaB51114a619C8104C38a309ccA236E1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boneswap-quick-wmatic-bone',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BONEswap', 'MATIC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x80244C2441779361F35803b8C711C6c8fC6054a3/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x80244C2441779361F35803b8C711C6c8fC6054a3',
    createdAt: 1625567504,
  },
  {
    id: 'boneswap-sushi-wmatic-bone-eol',
    name: 'BONE-MATIC SLP',
    token: 'BONE-MATIC SLP',
    tokenDescription: 'SushiSwap (BoneSwap)',
    tokenAddress: '0x4026895A93D720083E4469eE675156A8Ff8D3853',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoneSwapSushiBONE-WMATIC',
    earnedTokenAddress: '0x9fc153db31a5CeF1Cd326De31FEb37C7499eebC8',
    earnContractAddress: '0x9fc153db31a5CeF1Cd326De31FEb37C7499eebC8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boneswap-sushi-wmatic-bone',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BONEswap', 'MATIC'],
    retireReason: 'rewards',
    addLiquidityUrl: 'https://app.sushi.com/add/ETH/0x80244C2441779361F35803b8C711C6c8fC6054a3',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x80244C2441779361F35803b8C711C6c8fC6054a3',
    createdAt: 1625569831,
  },
  {
    id: 'boneswap-ape-wmatic-bone-eol',
    name: 'BONE-MATIC ALP',
    token: 'BONE-MATIC ALP',
    tokenDescription: 'ApeSwap (BoneSwap)',
    tokenAddress: '0xbCD83aB205A14648674FE67c97AAD89B261cBaC6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoneSwapApeBONE-WMATIC',
    earnedTokenAddress: '0x7D59B0cAC35d431fe5B2F3aAED2FB70050B2113D',
    earnContractAddress: '0x7D59B0cAC35d431fe5B2F3aAED2FB70050B2113D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boneswap-ape-wmatic-bone',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BONEswap', 'MATIC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x80244C2441779361F35803b8C711C6c8fC6054a3',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x80244C2441779361F35803b8C711C6c8fC6054a3',
    createdAt: 1625570159,
  },
  {
    id: 'boneswap-quick-usdc-bone-eol',
    name: 'BONE-USDC LP',
    token: 'BONE-USDC LP',
    tokenDescription: 'Quickswap (BoneSwap)',
    tokenAddress: '0xc4DA04f471210063f8C79a09D6152D63085451a5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoneSwapQuickBONE-USDC',
    earnedTokenAddress: '0x1001844Cb9Bc3B89a60ce4f4773dBa3B27115230',
    earnContractAddress: '0x1001844Cb9Bc3B89a60ce4f4773dBa3B27115230',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boneswap-quick-usdc-bone',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BONEswap', 'USDC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x80244C2441779361F35803b8C711C6c8fC6054a3/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x80244C2441779361F35803b8C711C6c8fC6054a3',
    createdAt: 1625571379,
  },
  {
    id: 'polyyeld-quick-wmatic-yeld-eol',
    name: 'YELD-MATIC QLP',
    token: 'YELD-MATIC QLP',
    tokenDescription: 'Quickswap (Polyyeld)',
    tokenAddress: '0xef71F798c15f8C9732488e109BB511144088bECe',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldWMATIC-YELD',
    earnedTokenAddress: '0xbea5D2Aed651F579fa3e7ADAA2eC51276B2ea420',
    earnContractAddress: '0xbea5D2Aed651F579fa3e7ADAA2eC51276B2ea420',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-quick-wmatic-yeld',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['YELD', 'MATIC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891&outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    createdAt: 1624239175,
  },
  {
    id: 'dfyn-route-dfyn-eol',
    name: 'ROUTE-DFYN LP',
    token: 'ROUTE-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0xB0dc320ea9eea823a150763AbB4A7bA8286Cd08B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFYNROUTE-DFYN',
    earnedTokenAddress: '0xad88D6B731DCb567a3E13407f1E7B0649Bad82D2',
    earnContractAddress: '0xad88D6B731DCb567a3E13407f1E7B0649Bad82D2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-route-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['ROUTE', 'DFYN'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    createdAt: 1625512841,
  },
  {
    id: 'dfyn-sx-dfyn-eol',
    name: 'SX-DFYN LP',
    token: 'SX-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x863325397751C51f85818A7958a7Dd45209bf501',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFYNSX-DFYN',
    earnedTokenAddress: '0x970283Df294f9cedD2187bf84782AB75617Be31c',
    earnContractAddress: '0x970283Df294f9cedD2187bf84782AB75617Be31c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-sx-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['SX', 'DFYN'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    createdAt: 1625512471,
  },
  {
    id: 'dfyn-ez-dfyn-eol',
    name: 'EZ-DFYN LP',
    token: 'EZ-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x3a89F0B87E6249Cc44A9420EfBe96ed6173aD0Dc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFYNEZ-DFYN',
    earnedTokenAddress: '0x0E765383bea3a166A87a042132422f5F29fDb217',
    earnContractAddress: '0x0E765383bea3a166A87a042132422f5F29fDb217',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-ez-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['EZ', 'DFYN'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x34C1b299A74588D6Abdc1b85A53345A48428a521/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x34C1b299A74588D6Abdc1b85A53345A48428a521&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    createdAt: 1625512895,
  },
  {
    id: 'dfyn-uft-dfyn-eol',
    name: 'UFT-DFYN LP',
    token: 'UFT-DFYN DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x8f07eaC653cd812b3Ac691729CD16616E232de68',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDFYNUFT-DFYN',
    earnedTokenAddress: '0xD239D193809b04fD24CB0Cd56A99A3F9dB069C9d',
    earnContractAddress: '0xD239D193809b04fD24CB0Cd56A99A3F9dB069C9d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dfyn-uft-dfyn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['UFT', 'DFYN'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x5B4CF2C120A9702225814E18543ee658c5f8631e/0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x5B4CF2C120A9702225814E18543ee658c5f8631e&outputCurrency=0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    createdAt: 1625513153,
  },
  {
    id: 'polycat-dfyn-fish-matic-eol',
    logo: 'polygon/FISH-MATIC.svg',
    name: 'FISH-MATIC LP',
    token: 'FISH-MATIC DLP',
    tokenDescription: 'DFyn',
    tokenAddress: '0x9e2Fbb31fBd68472f6cd54A1635b8cd64d78FC1C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatDfynFISH-MATIC',
    earnedTokenAddress: '0x9f3B96a2Dd55aa904bC5476Ffe66E74a53f6b420',
    earnContractAddress: '0x9f3B96a2Dd55aa904bC5476Ffe66E74a53f6b420',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polycat-dfyn-fish-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['FISH', 'MATIC'],
    addLiquidityUrl:
      'https://exchange.dfyn.network/#/add/0x3a3df212b7aa91aa0402b9035b098891d276572b/ETH',
    buyTokenUrl:
      'https://exchange.dfyn.network/#/swap?inputCurrency=0x3a3df212b7aa91aa0402b9035b098891d276572b&outputCurrency=ETH',
    createdAt: 1625170837,
  },
  {
    id: 'polypup-bone-eol',
    logo: 'single-assets/BONE.png',
    name: 'BONE',
    token: 'BONE',
    tokenDescription: 'Polypup',
    tokenAddress: '0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupBONE',
    earnedTokenAddress: '0x8Fa291074B9E28055fFdBCd4C1C1Dad67B9a130A',
    earnContractAddress: '0x8Fa291074B9E28055fFdBCd4C1C1Dad67B9a130A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BONE',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BONE'],
    retireReason: 'rewards',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    createdAt: 1625093994,
  },
  {
    id: 'polypup-pup-eol',
    logo: 'single-assets/PUP.png',
    name: 'PUP',
    token: 'PUP',
    tokenDescription: 'Polypup',
    tokenAddress: '0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupPUP',
    earnedTokenAddress: '0xBe1C35d3349555Ba9eEa38AB1A21a6Db0Bb0fCdD',
    earnContractAddress: '0xBe1C35d3349555Ba9eEa38AB1A21a6Db0Bb0fCdD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'PUP',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['PUP'],
    retireReason: 'rewards',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    createdAt: 1624640068,
  },
  {
    id: 'polypup-wmatic-bone-eol',
    name: 'BONE-MATIC QLP',
    token: 'BONE-MATIC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0xeb168C9D678dC34D64337F4eA12cf14ed6fbE34B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupWMATIC-BONE',
    earnedTokenAddress: '0x6C433f102A6b8582a43222e335C73873538161b7',
    earnContractAddress: '0x6C433f102A6b8582a43222e335C73873538161b7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-wmatic-bone',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['BONE', 'MATIC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
    createdAt: 1625079217,
  },
  {
    id: 'polypup-usdc-pup-eol',
    name: 'PUP-USDC QLP',
    token: 'PUP-USDC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0x767f8BD67a5f133BdDF3b285c5E2FD3D157A2cdC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupUSDC-PUP',
    earnedTokenAddress: '0xB1893a79fcCe73aEfc878e5AC918698Ee0110444',
    earnContractAddress: '0xB1893a79fcCe73aEfc878e5AC918698Ee0110444',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-usdc-pup',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['PUP', 'USDC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    createdAt: 1625082947,
  },
  {
    id: 'polypup-wmatic-pup-eol',
    name: 'PUP-MATIC QLP',
    token: 'PUP-MATIC QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0xBC68d2A5920c4ffaEa20E2BE48a0E09055481976',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupWMATIC-PUP',
    earnedTokenAddress: '0x37884333d34eeE3EAe83439CE4608E69E7081116',
    earnContractAddress: '0x37884333d34eeE3EAe83439CE4608E69E7081116',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-wmatic-pup',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['PUP', 'MATIC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    createdAt: 1624581505,
  },
  {
    id: 'ape-matic-usdt',
    name: 'MATIC-USDT ALP',
    token: 'MATIC-USDT ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x65D43B64E3B31965Cd5EA367D4c2b94c03084797',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-USDT',
    earnedTokenAddress: '0x1BE356364a1e849af2F7a513Fc46dAB6063Db485',
    earnContractAddress: '0x1BE356364a1e849af2F7a513Fc46dAB6063Db485',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-matic-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['MATIC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    createdAt: 1624811048,
  },
  {
    id: 'ape-matic-dai',
    name: 'MATIC-DAI ALP',
    token: 'MATIC-DAI ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xd32f3139A214034A0f9777c87eE0a064c1FF6AE2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-DAI',
    earnedTokenAddress: '0x584611DA226b4d4C0c4D880E6f1E0c0E8522f3AE',
    earnContractAddress: '0x584611DA226b4d4C0c4D880E6f1E0c0E8522f3AE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-matic-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['MATIC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    createdAt: 1624810792,
  },
  {
    id: 'polypup-weth-pup-eol',
    name: 'PUP-ETH QLP',
    token: 'PUP-ETH QLP',
    tokenDescription: 'Polypup',
    tokenAddress: '0x0d6fF4e5a446C3e7ae84C838bbF5e3b6b2E26093',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolypupWETH-PUP',
    earnedTokenAddress: '0x4D42bD06425179e53498D364427Af4Bf50d27658',
    earnContractAddress: '0x4D42bD06425179e53498D364427Af4Bf50d27658',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypup-weth-pup-eol',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['PUP', 'ETH'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
    createdAt: 1624580199,
  },
  {
    id: 'quick-eth-ramp-eol',
    name: 'RAMP-ETH LP',
    token: 'RAMP-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xe55739E1fEb9F9aED4Ce34830a06cA6Cc37494A0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-RAMP',
    earnedTokenAddress: '0x94242E121F056FA7F0892452e6a17678124981c1',
    earnContractAddress: '0x94242E121F056FA7F0892452e6a17678124981c1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-ramp',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['RAMP', 'ETH'],
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2',
    createdAt: 1624595881,
  },
  {
    id: 'quick-usdc-rusd-eol',
    name: 'rUSD-USDC LP',
    token: 'rUSD-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5EF8747d1dc4839e92283794a10d448357973aC0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-rUSD',
    earnedTokenAddress: '0x2b17aD11c5e9553c79a18F33Df4dE565eF0ad5b0',
    earnContractAddress: '0x2b17aD11c5e9553c79a18F33Df4dE565eF0ad5b0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-rusd',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['rUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5',
    createdAt: 1624637764,
  },
  {
    id: 'polyyeld-ape-wmatic-yeld-eol',
    name: 'YELD-MATIC ALP',
    token: 'YELD-MATIC ALP',
    tokenDescription: 'ApeSwap (Polyyeld)',
    tokenAddress: '0xC1Dd63cA154837AC4356D888F7c10fbBe442407e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldApeWMATIC-YELD',
    earnedTokenAddress: '0xd73AEF83c08264C5600C3a17f009B6f8c0226221',
    earnContractAddress: '0xd73AEF83c08264C5600C3a17f009B6f8c0226221',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-ape-wmatic-yeld',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['YELD', 'MATIC'],
    addLiquidityUrl:
      'https://app.apeswap.finance/add/0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?inputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891&outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    createdAt: 1624588799,
  },
  {
    id: 'sushi-wbtc-ibbtc',
    name: 'ibBTC-WBTC SLP',
    token: 'ibBTC-WBTC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x8F8e95Ff4B4c5E354ccB005c6B0278492D7B5907',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiWBTC-ibBTC',
    earnedTokenAddress: '0xD35B3733c2ceaf3635bE246B2c6C42f10e5b6B78',
    earnContractAddress: '0xD35B3733c2ceaf3635bE246B2c6C42f10e5b6B78',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-wbtc-ibbtc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ibBTC', 'WBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x4EaC4c4e9050464067D673102F8E24b2FccEB350',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&outputCurrency=0x4EaC4c4e9050464067D673102F8E24b2FccEB350',
    createdAt: 1624343679,
  },
  {
    id: 'ape-eth-matic',
    name: 'ETH-MATIC ALP',
    token: 'ETH-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x6Cf8654e85AB489cA7e70189046D507ebA233613',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-ETH',
    earnedTokenAddress: '0xc24Cf5fA29E619f2D5ccbEC46F2295876c3722ff',
    earnContractAddress: '0xc24Cf5fA29E619f2D5ccbEC46F2295876c3722ff',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-eth-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ETH', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1624378113,
  },
  {
    id: 'ape-btc-matic',
    name: 'BTC-MATIC ALP',
    token: 'BTC-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xe82635a105c520fd58e597181cBf754961d51E3e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-BTC',
    earnedTokenAddress: '0x76F0e4a08c1e85d020dfD7C66B991ecd4A7551AF',
    earnContractAddress: '0x76F0e4a08c1e85d020dfD7C66B991ecd4A7551AF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-btc-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['WBTC', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    createdAt: 1624376861,
  },
  {
    id: 'ape-bnb-matic',
    name: 'BNB-MATIC ALP',
    token: 'BNB-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x0359001070cF696D5993E0697335157a6f7dB289',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSwapMATIC-BNB',
    earnedTokenAddress: '0x6888f67662d1f442C4428129e0Bdb27a275e0a65',
    earnContractAddress: '0x6888f67662d1f442C4428129e0Bdb27a275e0a65',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-bnb-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BNB', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    createdAt: 1624375449,
  },
  {
    id: 'polyyeld-sushi-wmatic-yeld-eol',
    name: 'YELD-MATIC SLP',
    token: 'YELD-MATIC SLP',
    tokenDescription: 'SushiSwap (Polyyeld)',
    tokenAddress: '0xdD650C8d274474FF1af1152B3B27f2702AcA8a98',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldSushiWMATIC-YELD',
    earnedTokenAddress: '0x0Ca850eEfE051ED244846A2939e218ec6D44a0b2',
    earnContractAddress: '0x0Ca850eEfE051ED244846A2939e218ec6D44a0b2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-sushi-wmatic-yeld',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['YELD', 'MATIC'],
    addLiquidityUrl:
      'https://app.sushi.com/add/0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891&outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    createdAt: 1624375717,
  },
  {
    id: 'polyyeld-quick-usdc-yeld-eol',
    name: 'YELD-USDC QLP',
    token: 'YELD-USDC QLP',
    tokenDescription: 'Quickswap (Polyyeld)',
    tokenAddress: '0xAD8f4c66b28756458Fc419AA67aD2207A21A6997',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolyyeldUSDC-YELD',
    earnedTokenAddress: '0x9a8390F3F74E7f367B5f948dd04495B67a54a5F4',
    earnContractAddress: '0x9a8390F3F74E7f367B5f948dd04495B67a54a5F4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polyyeld-quick-usdc-yeld',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['USDC', 'YELD'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    createdAt: 1624239489,
  },
  {
    id: 'quick-pbnb-quick-eol',
    name: 'pBNB-QUICK QLP',
    token: 'pBNB-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x53e27DaDf6473d062717be8807C453Af212c7102',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickPBNB-QUICK',
    earnedTokenAddress: '0x1A90Ea15e00a6c647478e86e3A3DB1aC1eB23cE5',
    earnContractAddress: '0x1A90Ea15e00a6c647478e86e3A3DB1aC1eB23cE5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-pbnb-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['PBNB', 'QUICK'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267/0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267&outputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    createdAt: 1624037936,
  },
  {
    id: 'quick-usdc-pbnb-eol',
    name: 'pBNB-USDC QLP',
    token: 'pBNB-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-PBNB',
    earnedTokenAddress: '0x61f55dc5243398D57acd5d6265e228da65C4Cb52',
    earnContractAddress: '0x61f55dc5243398D57acd5d6265e228da65C4Cb52',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-pbnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['USDC', 'PBNB'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xE55cb144E02Cffb6fBD65d9a4BD62378998Bc267&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    createdAt: 1623985582,
  },
  {
    id: 'quick-dai-usdt-eol',
    name: 'DAI-USDT LP',
    token: 'DAI-USDT QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x59153f27eeFE07E5eCE4f9304EBBa1DA6F53CA88',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDAI-USDT',
    earnedTokenAddress: '0x8F1F1FB23A208041e1f4Bf4A3b4E165bcCA25Bbb',
    earnContractAddress: '0x8F1F1FB23A208041e1f4Bf4A3b4E165bcCA25Bbb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-dai-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['DAI', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    createdAt: 1623912540,
  },
  {
    id: 'quick-eth-fff-eol',
    name: 'FFF-ETH LP',
    token: 'FFF-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x4935270Aa19bA0B88Fe99eA9FeB10519feAfA758',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-FFF',
    earnedTokenAddress: '0xa5b0E0f38BC86723a9893B828a4B9595ecb22F42',
    earnContractAddress: '0xa5b0E0f38BC86723a9893B828a4B9595ecb22F42',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-fff',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['FFF', 'ETH'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x9aceb6f749396d1930abc9e263efc449e5e82c13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x9aceb6f749396d1930abc9e263efc449e5e82c13',
    createdAt: 1623786134,
  },
  {
    id: 'wexpoly-wbtc-usdc-eol',
    name: 'WBTC-USDC LP',
    token: 'WBTC-USDC WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x30eEf213D4B9C809f5776Ae56cC39f02f19F742f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWBTC-USDC',
    earnedTokenAddress: '0x5c7fd860fC0072cFef2Cc4aB17CC7fF25B639d8b',
    earnContractAddress: '0x5c7fd860fC0072cFef2Cc4aB17CC7fF25B639d8b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wbtc-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['WBTC', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    createdAt: 1623864761,
  },
  {
    id: 'wexpoly-wbtc-eth-eol',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x30AdBCAb9Fbb4436109fcf3058D1Af27E6E33F3F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWBTC-ETH',
    earnedTokenAddress: '0x75A59e8d6611e90e7A8e439Cb59D9f78e738d16F',
    earnContractAddress: '0x75A59e8d6611e90e7A8e439Cb59D9f78e738d16F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wbtc-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['WBTC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1623864893,
  },
  {
    id: 'wexpoly-matic-eth-eol',
    logo: 'polygon/ETH-MATIC.png',
    name: 'MATIC-ETH LP',
    token: 'MATIC-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x679B8Ab80F298bF802fB294137765C6386D43dcA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyMATIC-ETH',
    earnedTokenAddress: '0xCB171DF46CA6FF7AfEF6c4935128204435E4F05C',
    earnContractAddress: '0xCB171DF46CA6FF7AfEF6c4935128204435E4F05C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-matic-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['MATIC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/MATIC/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1623848947,
  },
  {
    id: 'wexpoly-polydoge-matic-eol',
    name: 'POLYDOGE-MATIC LP',
    token: 'POLYDOGE-MATIC WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x1a881D021d346C5433B2cf8FB46Ee00Dec89070d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyPOLYDOGE-MATIC',
    earnedTokenAddress: '0xdf4E615e05713f9Be712bb999B3190Fd238bb77A',
    earnContractAddress: '0xdf4E615e05713f9Be712bb999B3190Fd238bb77A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-polydoge-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['PolyDoge', 'MATIC'],
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/MATIC/0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
    createdAt: 1624043620,
  },
  {
    id: 'wexpoly-snx-eth-eol',
    name: 'SNX-ETH LP',
    token: 'SNX-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x2857Ec555c1E4fA20982AcBf985b8b8807157A5B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolySNX-ETH',
    earnedTokenAddress: '0xf7e1226F6f98D1981e88Da328347F0E2131A2864',
    earnContractAddress: '0xf7e1226F6f98D1981e88Da328347F0E2131A2864',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-snx-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['SNX', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
    createdAt: 1624054007,
  },
  {
    id: 'wexpoly-link-eth-eol',
    name: 'LINK-ETH LP',
    token: 'LINK-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x70bF5ffCC6090A8D243FD05485Ec4c084BD10Ae5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyLINK-ETH',
    earnedTokenAddress: '0x23Ee6ED971ae7731F53913D9f8a45C7C1af3D6D5',
    earnContractAddress: '0x23Ee6ED971ae7731F53913D9f8a45C7C1af3D6D5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-link-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['LINK', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
    createdAt: 1624055417,
  },
  {
    id: 'wexpoly-aave-eth-eol',
    name: 'AAVE-ETH LP',
    token: 'AAVE-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x58401db5eD194cBA60f2b7D59b9619D54e0B493c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyAAVE-ETH',
    earnedTokenAddress: '0xc4cC677bb8b7E6EEA6409Ee2af9F434BAB004192',
    earnContractAddress: '0xc4cC677bb8b7E6EEA6409Ee2af9F434BAB004192',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-aave-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['AAVE', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    createdAt: 1624057500,
  },
  {
    id: 'wexpoly-dai-eth-eol',
    name: 'DAI-ETH LP',
    token: 'DAI-ETH WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xaEF35d720C56eF3c259BCCdB6931F331c7f15b3F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyDAI-ETH',
    earnedTokenAddress: '0x3a3a9784Af130d692E19A3f1C1b13eF44301D0f7',
    earnContractAddress: '0x3a3a9784Af130d692E19A3f1C1b13eF44301D0f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-dai-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['DAI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    createdAt: 1624062063,
  },
  {
    id: 'wexpoly-usdc-dai-eol',
    name: 'USDC-DAI LP',
    token: 'USDC-DAI WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x8E5c5AE2542116b5715b12bb2d391A770a607075',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooWexPolyUSDC-DAI',
    earnedTokenAddress: '0x5E7156F7c0B5E2005000C38beb38540ba9c283df',
    earnContractAddress: '0x5E7156F7c0B5E2005000C38beb38540ba9c283df',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-usdc-dai',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    createdAt: 1624058722,
  },
  {
    id: 'wexpoly-matic-usdc-eol',
    logo: 'polygon/MATIC-USDC.png',
    name: 'MATIC-USDC LP',
    token: 'MATIC-USDC WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x8312A29A91D9Fac706F4d2ADEB1FA4540faD1673',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyMATIC-USDC',
    earnedTokenAddress: '0xeCBb84E73760d0E22BDBeE79490c691386B5008F',
    earnContractAddress: '0xeCBb84E73760d0E22BDBeE79490c691386B5008F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-matic-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['MATIC', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/MATIC/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    createdAt: 1623849475,
  },
  {
    id: 'wexpoly-usdc-usdt-eol',
    logo: 'polygon/USDC-USDT.png',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x7242e19A0937ac33472febD69462668a4cf5bbC5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyUSDC-USDT',
    earnedTokenAddress: '0x58C55B5675B106b440635E2c550A771f4E256D35',
    earnContractAddress: '0x58C55B5675B106b440635E2c550A771f4E256D35',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['USDT', 'USDC'],
    withdrawalFee: '0.01%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    createdAt: 1623753043,
  },
  {
    id: 'wexpoly-wex-usdc-eol',
    logo: 'polygon/WEX-USDC.png',
    name: 'WEXpoly-USDC LP',
    token: 'WEXpoly-USDC LP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x5DE6a3CcA10d3F788EEdbD4923e31D4658bf6f9a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWEX-USDC',
    earnedTokenAddress: '0xe3B5a0d5dFDCD6dE7e22a5c2B6b957aB76d2A085',
    earnContractAddress: '0xe3B5a0d5dFDCD6dE7e22a5c2B6b957aB76d2A085',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wex-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['WEXpoly', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    createdAt: 1623752473,
  },
  {
    id: 'wexpoly-wex-eol',
    logo: 'polygon/WEX.png',
    name: 'WEXpoly',
    token: 'WEXpoly',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWEXpoly',
    earnedTokenAddress: '0x1A1432b90a093CbFFeCb44cf6965e2fd11005373',
    earnContractAddress: '0x1A1432b90a093CbFFeCb44cf6965e2fd11005373',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WEXpoly',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    assets: ['WEXpoly'],
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    createdAt: 1623690831,
  },
  {
    id: 'wexpoly-wex-matic-eol',
    logo: 'polygon/WEX-MATIC.png',
    name: 'WEXpoly-MATIC LP',
    token: 'WEXpoly-MATIC LP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xC46991072C7eAC184F7bd69d3122cd1750300b27',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyWEX-MATIC',
    earnedTokenAddress: '0xe09888EEab19bce85e67eDC59521F3f290B1BCcE',
    earnContractAddress: '0xe09888EEab19bce85e67eDC59521F3f290B1BCcE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-wex-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['WEXpoly', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
    createdAt: 1623688343,
  },
  {
    id: 'wexpoly-bifi-matic-eol',
    logo: 'polygon/BIFI-MATIC.png',
    name: 'BIFI-MATIC LP',
    token: 'BIFI-MATIC LP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xE60afed80406190C3AB2C17501d417097Dd741DB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWexPolyBIFI-MATIC',
    earnedTokenAddress: '0x247303D4Be227Aa87Bd52F05aDDAD772794DE394',
    earnContractAddress: '0x247303D4Be227Aa87Bd52F05aDDAD772794DE394',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wexpoly-bifi-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['BIFI', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.wault.finance/polygon/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xFbdd194376de19a88118e84E279b977f165d01b8',
    buyTokenUrl:
      'https://swap.wault.finance/polygon/#/swap?outputCurrency=0xFbdd194376de19a88118e84E279b977f165d01b8',
    createdAt: 1623689233,
  },
  {
    id: 'sushi-eth-wfil-eol',
    logo: 'polygon/ETH-FIL.png',
    name: 'ETH-FIL SLP',
    token: 'ETH-FIL SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x21Ef14B5580a852477ef31E7Ea9373485bF50377',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-WFIL',
    earnedTokenAddress: '0xA4fB94990f99A8C1290e83d40DB9C648fD868D14',
    earnContractAddress: '0xA4fB94990f99A8C1290e83d40DB9C648fD868D14',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-wfil',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SushiSwap',
    assets: ['ETH', 'WFIL'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0xEde1B77C0Ccc45BFa949636757cd2cA7eF30137F/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xEde1B77C0Ccc45BFa949636757cd2cA7eF30137F&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1623529777,
  },
  {
    id: 'quick-usdc-dai',
    logo: 'polygon/USDC-DAI.png',
    name: 'USDC-DAI LP',
    token: 'USDC-DAI QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xf04adBF75cDFc5eD26eeA4bbbb991DB002036Bdd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-DAI',
    earnedTokenAddress: '0x0dFd8c4dd493d8f87Be362878E41537Ca7Ee4d9e',
    earnContractAddress: '0x0dFd8c4dd493d8f87Be362878E41537Ca7Ee4d9e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    createdAt: 1623516421,
  },
  {
    id: 'quick-quick-uni-eol',
    logo: 'polygon/QUICK-UNI.png',
    name: 'QUICK-UNI LP',
    token: 'QUICK-UNI QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x4B4C614b9219397c02296f6F4e2351259840B3C7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-UNI',
    earnedTokenAddress: '0xaF34573353aBd160889889D52d7841B2bBCE7Cf9',
    earnContractAddress: '0xaF34573353aBd160889889D52d7841B2bBCE7Cf9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-uni',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['QUICK', 'UNI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13&outputCurrency=0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    createdAt: 1623516309,
  },
  {
    id: 'quick-link-quick-eol',
    logo: 'polygon/LINK-QUICK.png',
    name: 'LINK-QUICK LP',
    token: 'LINK-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xDEA8f0F1E6E98C6AEE891601600E5fBa294B5e36',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickLINK-QUICK',
    earnedTokenAddress: '0xdD32ca42a5bab4073D319BC26bb4e951e767Ba6E',
    earnContractAddress: '0xdD32ca42a5bab4073D319BC26bb4e951e767Ba6E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-link-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['LINK', 'QUICK'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39/0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39&outputCurrency=0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    createdAt: 1623515927,
  },
  {
    id: 'polycat-fish-eol',
    logo: 'single-assets/FISH.svg',
    name: 'FISH',
    token: 'FISH',
    tokenDescription: 'Polycat',
    tokenAddress: '0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatFish',
    earnedTokenAddress: '0xcC16BbE4987920a17F5C4a92C1Ab4dbDfB0B9790',
    earnContractAddress: '0xcC16BbE4987920a17F5C4a92C1Ab4dbDfB0B9790',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'FISH',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['FISH'],
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
    createdAt: 1623280369,
  },
  {
    id: 'quick-usdc-eth',
    logo: 'polygon/USDC-ETH.png',
    name: 'USDC-ETH LP',
    token: 'USDC-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x853Ee4b2A13f8a742d64C8F088bE7bA2131f670d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-ETH',
    earnedTokenAddress: '0x5d4B83B3011B1E14e55185c5D432987e76f6DE3C',
    earnContractAddress: '0x5d4B83B3011B1E14e55185c5D432987e76f6DE3C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1622693962,
  },
  {
    id: 'quick-dai-eth',
    logo: 'polygon/ETH-DAI.png',
    name: 'DAI-ETH LP',
    token: 'DAI-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x4A35582a710E1F4b2030A3F826DA20BfB6703C09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDAI-ETH',
    earnedTokenAddress: '0x9DA4048550C1a73686E594f726310F0b0585fBA3',
    earnContractAddress: '0x9DA4048550C1a73686E594f726310F0b0585fBA3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-dai-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DAI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1622675730,
  },
  {
    id: 'quick-fff-quick-eol',
    logo: 'polygon/FFF-QUICK.png',
    name: 'FFF-QUICK LP',
    token: 'FFF-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2648ec89875d944e38f55925Df77D9Cfe0B01Edd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickFFF-QUICK',
    earnedTokenAddress: '0x2D4cf116A59e24Dd0aB8821c93AE87658a9483b6',
    earnContractAddress: '0x2D4cf116A59e24Dd0aB8821c93AE87658a9483b6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-fff-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['FFF', 'QUICK'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x9aceb6f749396d1930abc9e263efc449e5e82c13/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x9aceb6f749396d1930abc9e263efc449e5e82c13',
    createdAt: 1623016533,
  },
  {
    id: 'aave-wbtc-eol',
    logo: 'single-assets/BTCB.svg',
    name: 'WBTC',
    token: 'WBTC',
    tokenDescription: 'Aave',
    tokenAddress: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveWBTC',
    earnedTokenAddress: '0xD3395577febc6AdaB25490a69955ebC47040766C',
    earnContractAddress: '0xD3395577febc6AdaB25490a69955ebC47040766C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Aave',
    retireReason: 'rewards',
    assets: ['WBTC'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    createdAt: 1620900077,
  },
  {
    id: 'aave-eth-eol',
    logo: 'single-assets/ETH.svg',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Aave',
    tokenAddress: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveETH',
    earnedTokenAddress: '0x77276a7c9Ff3a6cbD334524d6F1f6219D039ac0E',
    earnContractAddress: '0x77276a7c9Ff3a6cbD334524d6F1f6219D039ac0E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Aave',
    retireReason: 'rewards',
    assets: ['ETH'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1620569907,
  },
  {
    id: 'quick-titan-eth-eol',
    logo: 'polygon/TITAN-ETH.png',
    name: 'TITAN-ETH LP',
    token: 'TITAN-ETH LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xA28Ade2f27b9554b01964FDCe97eD643301411d9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickTITAN-ETH',
    earnedTokenAddress: '0x1b27b7083a9BdA1aCD04266F61DF646f8296a872',
    earnContractAddress: '0x1b27b7083a9BdA1aCD04266F61DF646f8296a872',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-titan-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TITAN', 'ETH'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    createdAt: 1622729319,
  },
  {
    id: 'iron-iron-usdc-quick-eol',
    logo: 'polygon/IRON-USDC.png',
    name: 'IRON-USDC LP',
    token: 'IRON-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2Bbe0F728f4d5821F84eeE0432D2A4be7C0cB7Fc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronQuickIRON-USDC',
    earnedTokenAddress: '0xe5D9d4F4C6369E24c8dA4cB07cCb2901845f6021',
    earnContractAddress: '0xe5D9d4F4C6369E24c8dA4cB07cCb2901845f6021',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-iron-usdc-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['IRON', 'USDC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    createdAt: 1622727165,
  },
  {
    id: 'polycat-sushi-fish-matic-eol',
    logo: 'polygon/FISH-MATIC.svg',
    name: 'FISH-MATIC LP',
    token: 'FISH-MATIC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xcBF6f78981e63Ef813cb71852d72A060b583EECF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatSushiFISH-MATIC',
    earnedTokenAddress: '0xefA8E30cE4cc433cEA1B3b6006D69731A4FBd485',
    earnContractAddress: '0xefA8E30cE4cc433cEA1B3b6006D69731A4FBd485',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polycat-sushi-fish-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['FISH', 'MATIC'],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x3a3df212b7aa91aa0402b9035b098891d276572b',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x3a3df212b7aa91aa0402b9035b098891d276572b',
    createdAt: 1622658346,
  },
  {
    id: 'polycat-quick-fish-matic-eol',
    logo: 'polygon/FISH-MATIC.svg',
    name: 'FISH-MATIC LP',
    token: 'FISH-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x289cf2B63c5Edeeeab89663639674d9233E8668E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatQuickFISH-MATIC',
    earnedTokenAddress: '0x7eE71053102d54Fc843BaEBaf07277C2b6dB64f1',
    earnContractAddress: '0x7eE71053102d54Fc843BaEBaf07277C2b6dB64f1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polycat-quick-fish-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['FISH', 'MATIC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
    createdAt: 1622658318,
  },
  {
    id: 'sushi-grt-eth',
    logo: 'polygon/GRT-ETH.png',
    name: 'GRT-ETH SLP',
    token: 'GRT-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x1cedA73C034218255F50eF8a2c282E6B4c301d60',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiGRT-ETH',
    earnedTokenAddress: '0x8efCf419813F9E018cCaCda36151e5079c274fa4',
    earnContractAddress: '0x8efCf419813F9E018cCaCda36151e5079c274fa4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-grt-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['GRT', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x5fe2B58c013d7601147DcdD68C143A77499f5531/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x5fe2B58c013d7601147DcdD68C143A77499f5531&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1622513600,
  },
  {
    id: 'sushi-frax-fxs-eol',
    logo: 'polygon/FRAX-FXS.png',
    name: 'FRAX-FXS SLP',
    token: 'FRAX-FXS SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xd53a56AE0f48c9a03660cd36c2E4ae20493a1Eca',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiFRAX-FXS',
    earnedTokenAddress: '0xddD5F39d044Dbaeef7b348cf04C3628acd3F1D8f',
    earnContractAddress: '0xddD5F39d044Dbaeef7b348cf04C3628acd3F1D8f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-frax-fxs',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SushiSwap',
    assets: ['FRAX', 'FXS'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x104592a158490a9228070E0A8e5343B499e125D0/0x3e121107F6F22DA4911079845a470757aF4e1A1b',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x104592a158490a9228070E0A8e5343B499e125D0&outputCurrency=0x3e121107F6F22DA4911079845a470757aF4e1A1b',
    createdAt: 1622513442,
  },
  {
    id: 'sushi-frax-usdc-eol',
    logo: 'polygon/FRAX-USDC.png',
    name: 'FRAX-USDC SLP',
    token: 'FRAX-USDC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x9e20a8d3501BF96EDA8e69b96DD84840058a1cB0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiFRAX-USDC',
    earnedTokenAddress: '0x5B19A2e8E5878cF2b1E9b1AC7CEA50346671B2Fc',
    earnContractAddress: '0x5B19A2e8E5878cF2b1E9b1AC7CEA50346671B2Fc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-frax-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SushiSwap',
    assets: ['FRAX', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x104592a158490a9228070E0A8e5343B499e125D0/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x104592a158490a9228070E0A8e5343B499e125D0&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    createdAt: 1622513282,
  },
  {
    id: 'sushi-wmatic-woofy-eol',
    logo: 'polygon/MATIC-WOOFY.png',
    name: 'MATIC-WOOFY SLP',
    token: 'MATIC-WOOFY SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x211F8e61113eDAf00cf37A804B0bA721875Ef560',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiMATIC-WOOFY',
    earnedTokenAddress: '0x544551E4E7D7bDd1CfbD55F07e304F5C9fD514Dd',
    earnContractAddress: '0x544551E4E7D7bDd1CfbD55F07e304F5C9fD514Dd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-wmatic-woofy',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SushiSwap',
    assets: ['WOOFY', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
    createdAt: 1622512744,
  },
  {
    id: 'sushi-usdc-dai',
    logo: 'polygon/USDC-DAI.png',
    name: 'USDC-DAI SLP',
    token: 'USDC-DAI SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xCD578F016888B57F1b1e3f887f392F0159E26747',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-DAI',
    earnedTokenAddress: '0x75424BE5378621AeC2eEF25965f40FeB59039B52',
    earnContractAddress: '0x75424BE5378621AeC2eEF25965f40FeB59039B52',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    createdAt: 1622177876,
  },
  {
    id: 'sushi-snx-eth-eol',
    logo: 'polygon/SNX-ETH.png',
    name: 'SNX-ETH SLP',
    token: 'SNX-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x116Ff0d1Caa91a6b94276b3471f33dbeB52073E7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiSNX-ETH',
    earnedTokenAddress: '0x3AD9cd8d75f7a711Caea58e725425A9dC0D249c4',
    earnContractAddress: '0x3AD9cd8d75f7a711Caea58e725425A9dC0D249c4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-snx-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SushiSwap',
    assets: ['SNX', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x50B728D8D964fd00C2d0AAD81718b71311feF68a/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x50B728D8D964fd00C2d0AAD81718b71311feF68a&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1622508134,
  },
  {
    id: 'iron-titan-eol',
    logo: 'polygon/TITAN.png',
    name: 'TITAN',
    token: 'TITAN',
    tokenDescription: 'IronFinance',
    tokenAddress: '0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronTITAN',
    earnedTokenAddress: '0x8e3e85c7d4A616683f3F9468bC17169774cD1aFC',
    earnContractAddress: '0x8e3e85c7d4A616683f3F9468bC17169774cD1aFC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'TITAN',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TITAN'],
    buyTokenUrl:
      'https://app.sushi.com/#/swap?outputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    createdAt: 1622454663,
  },
  {
    id: 'iron-titan-iron-eol',
    logo: 'polygon/TITAN-IRON.png',
    name: 'TITAN-IRON LP',
    token: 'TITAN-IRON LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x35c1895DAC1e2432b320e2927b4F71a0D995602F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronTITAN-IRON',
    earnedTokenAddress: '0x9525A39b7E642785c10F02287b6A181CE9552f44',
    earnContractAddress: '0x9525A39b7E642785c10F02287b6A181CE9552f44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-titan-iron',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TITAN', 'IRON'],
    addLiquidityUrl:
      'https://app.sushi.com/add/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A&outputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    createdAt: 1622452079,
  },
  {
    id: 'quick-bifi-eth-eol',
    logo: 'polygon/BIFI-ETH.png',
    name: 'BIFI-ETH LP',
    token: 'BIFI-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x8b80417D92571720949fC22404200AB8FAf7775f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickBIFI-ETH',
    earnedTokenAddress: '0x21bA98fCb000dFeD8eC3B94cB41BeA51A601A94c',
    earnContractAddress: '0x21bA98fCb000dFeD8eC3B94cB41BeA51A601A94c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-bifi-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['BIFI', 'ETH'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
    createdAt: 1622053127,
  },
  {
    id: 'quick-bifi-quick-eol',
    logo: 'polygon/BIFI-QUICK.png',
    name: 'BIFI-QUICK LP',
    token: 'BIFI-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xC6a23BDd628c3B17fc0dF270e6BF8E48511950e2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickBIFI-QUICK',
    earnedTokenAddress: '0xCC2755476B0573F0ee0D5a754Bb6fE720c3641Bb',
    earnContractAddress: '0xCC2755476B0573F0ee0D5a754Bb6fE720c3641Bb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-bifi-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['BIFI', 'QUICK'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
    createdAt: 1622053085,
  },
  {
    id: 'quick-degen-quick-eol',
    logo: 'polygon/DEGEN-QUICK.png',
    name: 'DEGEN-QUICK LP',
    token: 'DEGEN-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x75a4917aeE97BDA48D3572f431e04003743da85e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEGEN-QUICK',
    earnedTokenAddress: '0xe942A8Ef245EAC3CEa951486e3Df5764C79b9621',
    earnContractAddress: '0xe942A8Ef245EAC3CEa951486e3Df5764C79b9621',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-degen-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['DEGEN', 'QUICK'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
    createdAt: 1622087987,
  },
  {
    id: 'quick-cc10-quick-eol',
    logo: 'polygon/CC10-QUICK.png',
    name: 'CC10-QUICK LP',
    token: 'CC10-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x42fD10ddc7628b82d80c3b2C286F3e79555Fd7A1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCC10-QUICK',
    earnedTokenAddress: '0x226a18Fb5eb7d9d1c4Eb1b5Cff957E0F1e3fd9Ed',
    earnContractAddress: '0x226a18Fb5eb7d9d1c4Eb1b5Cff957E0F1e3fd9Ed',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cc10-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['CC10', 'QUICK'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
    createdAt: 1622088077,
  },
  {
    id: 'quick-defi5-quick-eol',
    logo: 'polygon/DEFI5-QUICK.png',
    name: 'DEFI5-QUICK LP',
    token: 'DEFI5-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xa975CEEb05B379Cf67FdfA63e7b2770f9F1D72c6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEFI5-QUICK',
    earnedTokenAddress: '0x191E0b3B023fd8911c1D7632086A46C0D2dB39ed',
    earnContractAddress: '0x191E0b3B023fd8911c1D7632086A46C0D2dB39ed',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-defi5-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['DEFI5', 'QUICK'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x42435F467D33e5C4146a4E8893976ef12BBCE762',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x42435F467D33e5C4146a4E8893976ef12BBCE762',
    createdAt: 1622088167,
  },
  {
    id: 'quick-btc-usdc',
    logo: 'polygon/BTC-USDC.png',
    name: 'WBTC-USDC LP',
    token: 'WBTC-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xF6a637525402643B0654a54bEAd2Cb9A83C8B498',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickwBTC-USDC',
    earnedTokenAddress: '0x91a55E4b057119e20334258f7C5EAB8822491CEb',
    earnContractAddress: '0x91a55E4b057119e20334258f7C5EAB8822491CEb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-btc-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['WBTC', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    createdAt: 1622047000,
  },
  {
    id: 'iron-titan-matic-eol',
    logo: 'polygon/TITAN-MATIC.png',
    name: 'TITAN-MATIC LP',
    token: 'TITAN-MATIC LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xA79983Daf2A92c2C902cD74217Efe3D8AF9Fba2a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronTITAN-MATIC',
    earnedTokenAddress: '0xE63aCEbE35265896cC6E8BdB8eCC0640a1807141',
    earnContractAddress: '0xE63aCEbE35265896cC6E8BdB8eCC0640a1807141',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-titan-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['TITAN', 'MATIC'],
    addLiquidityUrl: 'https://app.sushi.com/add/ETH/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?outputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    createdAt: 1622024916,
  },
  {
    id: 'iron-iron-usdc-eol',
    logo: 'polygon/IRON-USDC.png',
    name: 'IRON-USDC LP',
    token: 'IRON-USDC LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x85dE135fF062Df790A5f20B79120f17D3da63b2d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronIRON-USDC',
    earnedTokenAddress: '0x3e349b83A3E68bdD5BB71fAB63dDE123c478FEA4',
    earnContractAddress: '0x3e349b83A3E68bdD5BB71fAB63dDE123c478FEA4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-iron-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['IRON', 'USDC'],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?outputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    createdAt: 1622026912,
  },
  {
    id: 'quick-matic-usdc',
    logo: 'polygon/MATIC-USDC.png',
    name: 'MATIC-USDC LP',
    token: 'MATIC-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickMATIC-USDC',
    earnedTokenAddress: '0xC1A2e8274D390b67A7136708203D71BF3877f158',
    earnContractAddress: '0xC1A2e8274D390b67A7136708203D71BF3877f158',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-matic-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['MATIC', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    createdAt: 1621983287,
  },
  {
    id: 'quick-vision-eth-eol',
    logo: 'polygon/VISION-ETH.png',
    name: 'VISION-ETH LP',
    token: 'VISION-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x47Be4b1b6921a36591142E108B8C9E04BB55E015',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickVISION-ETH',
    earnedTokenAddress: '0xAbA81D550C326DFf2cE0D31bC7Aa6289d576591E',
    earnContractAddress: '0xAbA81D550C326DFf2cE0D31bC7Aa6289d576591E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-vision-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['VISION', 'ETH'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x034b2090b579228482520c589dbd397c53fc51cc',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x034b2090b579228482520c589dbd397c53fc51cc',
    createdAt: 1621961982,
  },
  {
    id: 'quick-degen-eth-eol',
    logo: 'polygon/DEGEN-ETH.png',
    name: 'DEGEN-ETH LP',
    token: 'DEGEN-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xFdBDb3A2bbdC7d9dC6203DcEf9D53f1735135951',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEGEN-ETH',
    earnedTokenAddress: '0xF7A5eC9168B4C5688b3ad599Aa5c8E1922fEacAE',
    earnContractAddress: '0xF7A5eC9168B4C5688b3ad599Aa5c8E1922fEacAE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-degen-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['DEGEN', 'ETH'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
    createdAt: 1621918051,
  },
  {
    id: 'quick-cc10-eth-eol',
    logo: 'polygon/CC10-ETH.png',
    name: 'CC10-ETH LP',
    token: 'CC10-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xBF646010B1ae86aD7fBb4cFf5fd93C7019331Cc9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCC10-ETH',
    earnedTokenAddress: '0x6F6bbbCA49b4b2cE0E27eb0156977048AC3434B9',
    earnContractAddress: '0x6F6bbbCA49b4b2cE0E27eb0156977048AC3434B9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cc10-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['CC10', 'ETH'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
    createdAt: 1621903565,
  },
  {
    id: 'pzap-pzap-usdc-eol',
    logo: 'polygon/PZAP-USDC.png',
    name: 'PZAP-USDC LP',
    token: 'PZAP-USDC LP',
    tokenDescription: 'Polyzap',
    tokenAddress: '0x1df7ADAeD1F7f36C822A3079198815b284aD8475',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPzapPZAP-USDC',
    earnedTokenAddress: '0xBA4FA9A5e1e573fA5267970238af5Edf40727315',
    earnContractAddress: '0xBA4FA9A5e1e573fA5267970238af5Edf40727315',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pzap-pzap-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['PZAP', 'USDC'],
    addLiquidityUrl:
      'https://polyzap.app/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
    buyTokenUrl:
      'https://polyzap.app/#/swap?outputCurrency=0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
    createdAt: 1621345663,
    retiredReason: 'tvl',
  },
  {
    id: 'pzap-pzap-matic-eol',
    logo: 'polygon/PZAP-MATIC.png',
    name: 'PZAP-MATIC LP',
    token: 'PZAP-MATIC LP',
    tokenDescription: 'Polyzap',
    tokenAddress: '0xf627E14c318045066dAe0A36997c8C1F34fC0D4c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPzapPZAP-MATIC',
    earnedTokenAddress: '0xf2984c27B963A14F9f3B7326096c54fb05d5b9AF',
    earnContractAddress: '0xf2984c27B963A14F9f3B7326096c54fb05d5b9AF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pzap-pzap-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['PZAP', 'MATIC'],
    addLiquidityUrl: 'https://polyzap.app/#/add/ETH/0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
    buyTokenUrl:
      'https://polyzap.app/#/swap?outputCurrency=0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
    createdAt: 1621425966,
    retiredReason: 'tvl',
  },
  {
    id: 'cometh-bifi-eth-eol',
    logo: 'polygon/BIFI-ETH.png',
    name: 'BIFI-ETH LP',
    token: 'BIFI-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0xee3987aa1B20944EF4f8f049Cf1763007C06cFBC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethBIFI-ETH',
    earnedTokenAddress: '0x9649e6E5c689f21BC27B47CE4177f7c5f7281E20',
    earnContractAddress: '0x9649e6E5c689f21BC27B47CE4177f7c5f7281E20',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-bifi-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Cometh',
    assets: ['BIFI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
    createdAt: 1621259977,
  },
  {
    id: 'cometh-bifi-must-eol',
    logo: 'polygon/BIFI-MUST.png',
    name: 'BIFI-MUST LP',
    token: 'BIFI-MUST LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x9292aa90F81dc77fE6e6BB34b276502b6a047F7d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethBIFI-MUST',
    earnedTokenAddress: '0x66b3d910c30f2305EA0078E06DF65e0c1745ceF0',
    earnContractAddress: '0x66b3d910c30f2305EA0078E06DF65e0c1745ceF0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-bifi-must',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Cometh',
    assets: ['BIFI', 'MUST'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
    createdAt: 1621260031,
  },
  {
    id: 'aave-matic-eol',
    logo: 'single-assets/MATIC.svg',
    name: 'MATIC',
    token: 'MATIC',
    tokenDescription: 'Aave',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveMATIC',
    earnedTokenAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    earnContractAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WMATIC',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Aave',
    retireReason: 'rewards',
    assets: ['MATIC'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    createdAt: 1621100435,
  },
  {
    id: 'aave-aave-eol',
    logo: 'single-assets/AAVE.svg',
    name: 'AAVE',
    token: 'AAVE',
    tokenDescription: 'Aave',
    tokenAddress: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveAAVE',
    earnedTokenAddress: '0xe4b3CCbB1E48c579Ea3D764Fb258Bc908e46487E',
    earnContractAddress: '0xe4b3CCbB1E48c579Ea3D764Fb258Bc908e46487E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'AAVE',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Aave',
    retireReason: 'rewards',
    assets: ['AAVE'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    createdAt: 1621097202,
  },
  {
    id: 'aave-dai-eol',
    logo: 'single-assets/DAI.svg',
    name: 'DAI',
    token: 'DAI',
    tokenDescription: 'Aave',
    tokenAddress: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveDAI',
    earnedTokenAddress: '0x9B36ECeaC46B70ACfB7C2D6F3FD51aEa87C31018',
    earnContractAddress: '0x9B36ECeaC46B70ACfB7C2D6F3FD51aEa87C31018',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Aave',
    retireReason: 'rewards',
    assets: ['DAI'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    createdAt: 1620900737,
  },
  {
    id: 'aave-usdc-eol',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Aave',
    tokenAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveUSDC',
    earnedTokenAddress: '0xE71f3C11D4535a7F8c5FB03FDA57899B2C9c721F',
    earnContractAddress: '0xE71f3C11D4535a7F8c5FB03FDA57899B2C9c721F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Aave',
    retireReason: 'rewards',
    assets: ['USDC'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    createdAt: 1620539520,
  },
  {
    id: 'sushi-crv-eth',
    logo: 'polygon/CRV-ETH.png',
    name: 'CRV-ETH LP',
    token: 'CRV-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x396E655C309676cAF0acf4607a868e0CDed876dB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiCRV-ETH',
    earnedTokenAddress: '0xE695fCeD8fD93eeE54204a7fC33323a60d41865A',
    earnContractAddress: '0xE695fCeD8fD93eeE54204a7fC33323a60d41865A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-crv-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['CRV', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x172370d5cd63279efa6d502dab29171933a610af/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x172370d5cd63279efa6d502dab29171933a610af&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1621010827,
  },
  {
    id: 'sushi-usdc-usdt',
    logo: 'polygon/USDC-USDT.png',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x4B1F1e2435A9C96f7330FAea190Ef6A7C8D70001',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-USDT',
    earnedTokenAddress: '0xB6B89a05ad8228b98d0D8a77e1a695c54500db3b',
    earnContractAddress: '0xB6B89a05ad8228b98d0D8a77e1a695c54500db3b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    createdAt: 1621012117,
  },
  {
    id: 'sushi-link-eth',
    logo: 'polygon/LINK-ETH.png',
    name: 'LINK-ETH LP',
    token: 'LINK-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x74D23F21F780CA26B47Db16B0504F2e3832b9321',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiLINK-ETH',
    earnedTokenAddress: '0xaE65a66B3c7f8cc1ba71cEA740C953aBa0F3Ce8b',
    earnContractAddress: '0xaE65a66B3c7f8cc1ba71cEA740C953aBa0F3Ce8b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-link-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['LINK', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1621012201,
  },
  {
    id: 'quick-mocean-matic-eol',
    logo: 'polygon/mOCEAN-MATIC.png',
    name: 'mOCEAN-MATIC LP',
    token: 'mOCEAN-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5a94F81D25c73eDdBDD84b84E8F6D36C58270510',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickmOCEAN-MATIC',
    earnedTokenAddress: '0x82303a4b2c821204A84AB2a068eC8EDf3Bc23061',
    earnContractAddress: '0x82303a4b2c821204A84AB2a068eC8EDf3Bc23061',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-mocean-matic',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['mOCEAN', 'MATIC'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x282d8efce846a88b159800bd4130ad77443fa1a1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
    createdAt: 1620755335,
  },
  {
    id: 'quick-any-quick-eol',
    logo: 'polygon/ANY-QUICK.png',
    name: 'ANY-QUICK LP',
    token: 'ANY-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xeb275d1d930F157504cca7D7AFCe38360C7302b5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickANY-QUICK',
    earnedTokenAddress: '0x2849095eE44eCd5f60Ed04f94e5BB45623A8e75a',
    earnContractAddress: '0x2849095eE44eCd5f60Ed04f94e5BB45623A8e75a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-any-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['ANY', 'QUICK'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
    createdAt: 1620765461,
  },
  {
    id: 'quick-frax-quick-eol',
    logo: 'polygon/FRAX-QUICK.png',
    name: 'FRAX-QUICK LP',
    token: 'FRAX-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2Aa7a18cEAbf2eF893D2f7c0145cC45e6f10b223',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickFRAX-QUICK',
    earnedTokenAddress: '0x442ca31De7E6732455e60e3641Ac076fa7a0905f',
    earnContractAddress: '0x442ca31De7E6732455e60e3641Ac076fa7a0905f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-frax-quick',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['FRAX', 'QUICK'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x104592a158490a9228070e0a8e5343b499e125d0/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x104592a158490a9228070e0a8e5343b499e125d0&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
    createdAt: 1620767599,
  },
  {
    id: 'sushi-aave-eth',
    logo: 'polygon/AAVE-ETH.png',
    name: 'AAVE-ETH LP',
    token: 'AAVE-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x2813D43463C374a680f235c428FB1D7f08dE0B69',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiAAVE-ETH',
    earnedTokenAddress: '0x866a910F3375d0dEBDdD904A36B3940aFcD29900',
    earnContractAddress: '0x866a910F3375d0dEBDdD904A36B3940aFcD29900',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-aave-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['AAVE', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1620418656,
  },
  {
    id: 'sushi-eth-dai',
    logo: 'polygon/ETH-DAI.png',
    name: 'ETH-DAI LP',
    token: 'ETH-DAI SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x6FF62bfb8c12109E8000935A6De54daD83a4f39f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-DAI',
    earnedTokenAddress: '0x6b6ca47520dDC9333B8bD09a1e64204648B63274',
    earnContractAddress: '0x6b6ca47520dDC9333B8bD09a1e64204648B63274',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    createdAt: 1620653470,
  },
  {
    id: 'sushi-btc-eth',
    logo: 'polygon/ETH-BTC.png',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xE62Ec2e799305E0D367b0Cc3ee2CdA135bF89816',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiBTC-ETH',
    earnedTokenAddress: '0x6530E351074f1f9fdf254dC7d7d8A44324E158a4',
    earnContractAddress: '0x6530E351074f1f9fdf254dC7d7d8A44324E158a4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-btc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['WBTC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1620655127,
  },
  {
    id: 'sushi-eth-usdt',
    logo: 'polygon/ETH-USDT.png',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xc2755915a85C6f6c1C0F3a86ac8C058F11Caa9C9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-USDT',
    earnedTokenAddress: '0xE8417099971151CD5CfAE264e25634Fac05cA2b3',
    earnContractAddress: '0xE8417099971151CD5CfAE264e25634Fac05cA2b3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    createdAt: 1620655517,
  },
  {
    id: 'sushi-usdc-eth',
    logo: 'polygon/USDC-ETH.png',
    name: 'USDC-ETH LP',
    token: 'USDC-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x34965ba0ac2451A34a0471F04CCa3F990b8dea27',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-ETH',
    earnedTokenAddress: '0xE4DB97A2AAFbfef40D1a4AE8B709f61d6756F8e1',
    earnContractAddress: '0xE4DB97A2AAFbfef40D1a4AE8B709f61d6756F8e1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1620655909,
  },
  {
    id: 'sushi-matic-eth',
    logo: 'polygon/ETH-MATIC.png',
    name: 'ETH-MATIC LP',
    token: 'MATIC-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xc4e595acDD7d12feC385E5dA5D43160e8A0bAC0E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiMATIC-ETH',
    earnedTokenAddress: '0xC8e809a9180d637Cc23dAf60b41B70CA1ad5Fc08',
    earnContractAddress: '0xC8e809a9180d637Cc23dAf60b41B70CA1ad5Fc08',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-matic-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['MATIC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1620656197,
  },
  {
    id: 'cometh-azuki-eth-eol',
    logo: 'polygon/AZUKI-ETH.png',
    name: 'AZUKI-ETH LP',
    token: 'AZUKI-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x92Bb3233F59561FC1fEC53EfC3339E4Af8E917F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethAZUKI-ETH',
    earnedTokenAddress: '0xe95d14D09a8F6034C582bd993A2F2aA8ecEC72f0',
    earnContractAddress: '0xe95d14D09a8F6034C582bd993A2F2aA8ecEC72f0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-azuki-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Cometh',
    assets: ['AZUKI', 'ETH'],
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x7CdC0421469398e0F3aA8890693d86c840Ac8931/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x7CdC0421469398e0F3aA8890693d86c840Ac8931',
    createdAt: 1620677262,
  },
  {
    id: 'cometh-doki-eth-eol',
    logo: 'polygon/DOKI-ETH.png',
    name: 'DOKI-ETH LP',
    token: 'DOKI-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0xcCeD5cB001D6081c4561bf7911F11Ccd9aAA1474',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethDOKI-ETH',
    earnedTokenAddress: '0x383489a0A5deA3f1499c638e0258F7e6a68a253f',
    earnContractAddress: '0x383489a0A5deA3f1499c638e0258F7e6a68a253f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-doki-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Cometh',
    assets: ['DOKI', 'ETH'],
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C',
    createdAt: 1620677292,
  },
  {
    id: 'quick-usdc-usdt',
    logo: 'polygon/USDC-USDT.png',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2cF7252e74036d1Da831d11089D326296e64a728',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-USDT',
    earnedTokenAddress: '0x4462817b53E76b722c2D174D0148ddb81452f1dE',
    earnContractAddress: '0x4462817b53E76b722c2D174D0148ddb81452f1dE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    createdAt: 1619825239,
  },
  {
    id: 'cometh-must-eth',
    logo: 'polygon/MUST-ETH.png',
    name: 'MUST-ETH LP',
    token: 'MUST-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x8826C072657983939c26E684edcfb0e4133f0B3d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethMUST-ETH',
    earnedTokenAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    earnContractAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-must-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['MUST', 'ETH'],
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    createdAt: 1620068910,
  },
  {
    id: 'quick-eth-btc',
    logo: 'polygon/ETH-BTC.png',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xdC9232E2Df177d7a12FdFf6EcBAb114E2231198D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickwBTC-ETH',
    earnedTokenAddress: '0xf26607237355D7c6183ea66EC908729E9c6eEB6b',
    earnContractAddress: '0xf26607237355D7c6183ea66EC908729E9c6eEB6b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-btc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['WBTC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
    createdAt: 1619797422,
  },
  {
    id: 'quick-ubt-eth-eol',
    logo: 'polygon/UBT-ETH.png',
    name: 'UBT-ETH LP',
    token: 'UBT-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xcc203f45A31aE086218170F6A9e9623fA1655486',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUBT-ETH',
    earnedTokenAddress: '0x942aa6324E5D0C102d3Ad6607495ac5e798a991a',
    earnContractAddress: '0x942aa6324E5D0C102d3Ad6607495ac5e798a991a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-ubt-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['UBT', 'ETH'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x7FBc10850caE055B27039aF31bD258430e714c62',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x7FBc10850caE055B27039aF31bD258430e714c62',
    createdAt: 1620048684,
  },
  {
    id: 'quick-defi5-eth-eol',
    logo: 'polygon/DEFI5-ETH.png',
    name: 'DEFI5-ETH LP',
    token: 'DEFI5-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x654E651b658f784406125400cf648588CB9773e8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEFI5-ETH',
    earnedTokenAddress: '0x53e674D5cC969930420d73E4b79Ee0D46cCdf6c4',
    earnContractAddress: '0x53e674D5cC969930420d73E4b79Ee0D46cCdf6c4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-defi5-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['DEFI5', 'ETH'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x42435F467D33e5C4146a4E8893976ef12BBCE762',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x42435F467D33e5C4146a4E8893976ef12BBCE762',
    createdAt: 1620048552,
  },
  {
    id: 'quick-wise-eth-eol',
    logo: 'polygon/WISE-ETH.png',
    name: 'WISE-ETH LP',
    token: 'WISE-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xDF8139e9BebecAdEcF48BeC8c8064CcEfB618e2b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickWISE-ETH',
    earnedTokenAddress: '0x76cE86B1e1b7a3983B26F7E57B2A0C8896f7eB0D',
    earnContractAddress: '0x76cE86B1e1b7a3983B26F7E57B2A0C8896f7eB0D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-wise-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['WISE', 'ETH'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a',
    createdAt: 1620048454,
  },
  {
    id: 'quick-cel-eth-eol',
    logo: 'polygon/CEL-ETH.png',
    name: 'CEL-ETH LP',
    token: 'CEL-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xEAA5E4620373d9Ded4DCB9267F46fcfc6698c867',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCEL-ETH',
    earnedTokenAddress: '0xeF1870FddC25586636bf8a3d7cCf4d298f6E072e',
    earnContractAddress: '0xeF1870FddC25586636bf8a3d7cCf4d298f6E072e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cel-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['CEL', 'ETH'],
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6',
    createdAt: 1620048432,
  },
  {
    id: 'quick-quick-eth',
    logo: 'polygon/QUICK-ETH.png',
    name: 'QUICK-ETH LP',
    token: 'QUICK-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x1Bd06B96dd42AdA85fDd0795f3B4A79DB914ADD5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-ETH',
    earnedTokenAddress: '0x66df1B2d22759D03A9f37BAaAc826089e56a5936',
    earnContractAddress: '0x66df1B2d22759D03A9f37BAaAc826089e56a5936',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
    createdAt: 1620048408,
  },
  {
    id: 'quick-aave-eth',
    logo: 'polygon/AAVE-ETH.png',
    name: 'AAVE-ETH LP',
    token: 'AAVE-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x90bc3E68Ba8393a3Bf2D79309365089975341a43',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickAAVE-ETH',
    earnedTokenAddress: '0x752948B4493d2Ee09c95F944A76005aEBF410087',
    earnContractAddress: '0x752948B4493d2Ee09c95F944A76005aEBF410087',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-aave-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['AAVE', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1619797482,
  },
  {
    id: 'quick-link-eth-eol',
    logo: 'polygon/LINK-ETH.png',
    name: 'LINK-ETH LP',
    token: 'LINK-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5cA6CA6c3709E1E6CFe74a50Cf6B2B6BA2Dadd67',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickLINK-ETH',
    earnedTokenAddress: '0xaB4105375FbE5F502B0da986F46ADf7a21762e52',
    earnContractAddress: '0xaB4105375FbE5F502B0da986F46ADf7a21762e52',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-link-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'QuickSwap',
    assets: ['LINK', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    createdAt: 1619797458,
  },
  {
    id: 'krill-krill-usdc-eol',
    logo: 'polygon/KRILL-USDC.png',
    name: 'KRILL-USDC LP',
    token: 'KRILL-USDC LP',
    tokenDescription: 'Polywhale',
    tokenAddress: '0x6405Ebc22cB0899FC21f414085Ac4044B4721a0d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolywhaleKRILL-USDC',
    earnedTokenAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    earnContractAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'krill-krill-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['KRILL', 'USDC'],
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x05089c9ebffa4f0aca269e32056b1b36b37ed71b&outputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    createdAt: 1619551330,
  },
  {
    id: 'quick-eth-usdt',
    logo: 'polygon/ETH-USDT.png',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xF6422B997c7F54D1c6a6e103bcb1499EeA0a7046',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-USDT',
    earnedTokenAddress: '0xC422261EdC5dB679CAd9BC403e886351De540e77',
    earnContractAddress: '0xC422261EdC5dB679CAd9BC403e886351De540e77',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ETH', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    createdAt: 1619717061,
  },
  {
    id: 'quick-eth-matic',
    name: 'ETH-MATIC LP',
    token: 'ETH-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xadbF1854e5883eB8aa7BAf50705338739e558E5b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-MATIC',
    earnedTokenAddress: '0x8b89477dFde285849E1B07947E25012206F4D674',
    earnContractAddress: '0x8b89477dFde285849E1B07947E25012206F4D674',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ETH', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=ETH',
    createdAt: 1619717027,
  },
  {
    id: 'quick-quick-matic',
    logo: 'polygon/QUICK-MATIC.png',
    name: 'QUICK-MATIC LP',
    token: 'QUICK-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x019ba0325f1988213D448b3472fA1cf8D07618d7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-MATIC',
    earnedTokenAddress: '0xa008B727ddBa283Ddb178b47BB227Cdbea5C1bfD',
    earnContractAddress: '0xa008B727ddBa283Ddb178b47BB227Cdbea5C1bfD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
    createdAt: 1619716993,
  },
  {
    id: 'cometh-eth-matic',
    logo: 'polygon/ETH-MATIC.png',
    name: 'ETH-MATIC LP',
    token: 'ETH-MATIC LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x462390EA53F70F7570EEB2002c0150aF519f93A9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethETH-MATIC',
    earnedTokenAddress: '0xa5aaE3a55cA356C62b5425AA4bFC212542B17777',
    earnContractAddress: '0xa5aaE3a55cA356C62b5425AA4bFC212542B17777',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-eth-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['ETH', 'MATIC'],
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    createdAt: 1619529365,
  },
  {
    id: 'cometh-matic-must',
    logo: 'polygon/MUST-MATIC.png',
    name: 'MUST-MATIC LP',
    token: 'MUST-MATIC LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x80676b414a905De269D0ac593322Af821b683B92',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethMUST-MATIC',
    earnedTokenAddress: '0x7f6fE34C51d5352A0CF375C0Fbe03bD19eCD8460',
    earnContractAddress: '0x7f6fE34C51d5352A0CF375C0Fbe03bD19eCD8460',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-matic-must',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['MUST', 'MATIC'],
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    createdAt: 1619529345,
  },
  {
    id: 'cometh-usdc-must',
    logo: 'polygon/USDC-MUST.png',
    name: 'USDC-MUST LP',
    token: 'USDC-MUST LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x12A2aBcFcADc04681929F0c199bDf812967207E4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethUSDC-MUST',
    earnedTokenAddress: '0x8a198BCbF313A5565c64A7Ed61FaA413eB4E0931',
    earnContractAddress: '0x8a198BCbF313A5565c64A7Ed61FaA413eB4E0931',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-usdc-must',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['USDC', 'MUST'],
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    createdAt: 1619529291,
  },
];
